import React from 'react'
import DatePicker from 'react-datepicker'
import cx from 'classnames'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import styles from '../schedule.module.css'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDatePickerMonth } from './utils'

export default function WeekSelectorBanner(props) {
  const {
    handleNext,
    handlePrev,
    handleDatepicker,
    date,
    isDisabled,
    bookings,
  } = props

  const minDate = new Date(Object.keys(bookings)[0])
  const maxDate = new Date(Object.keys(bookings).slice(-1)[0])

  return (
    <div className={styles.banner}>
      <div
        key="prevWeek"
        onClick={handlePrev}
        className={cx(styles.prevWeek, {[styles.disabled]: isDisabled('prev')})}
        role="button"
        tabIndex="-1"
      >
        <ArrowBack className={styles.arrowIcon}/>
        <p className={styles.weekSwitchText}>Previous Week</p>
      </div>
      <div className={styles.datePickerContainer}>
        <DatePicker
          className={styles.datePicker}
          selected={date.toDate()}
          onChange={newDate => handleDatepicker(newDate)}
          dateFormat="MMMM yyyy"
          disabledKeyboardNavigation
          minDate={minDate}
          maxDate={maxDate}
          popperModifiers={{
            preventOverflow: {
              enabled: true,
            },
          }}
          customInput={<p className={styles.datePicker}>{formatDatePickerMonth(date)}</p>}
        />
      </div>
      <div
        key="nextWeek"
        onClick={handleNext}
        className={cx(styles.nextWeek, {[styles.disabled]: isDisabled('next')})}
        role="button"
        tabIndex="-1"
      >
        <p className={styles.weekSwitchText}>Next Week</p>
        <ArrowForward className={styles.arrowIcon}/>
      </div>
    </div>
  )
}
