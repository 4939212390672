import { filterInactiveLocations, isUpcoming } from '../../services/utils'

export const nameFormatter = (patient) =>
  `${patient.first_name} ${patient.last_name}`

export const patientList = (patients, appointmentToResched) => {
  if (appointmentToResched)
    return [
      patients.find(
        (patient) => patient.id === appointmentToResched.patient_id
      ),
    ]
  else
    return patients.reduce((acc, curr) => {
      const currNextVisits = curr.next_visits
      if (currNextVisits.some((visit) => !visit.booked))
        return [...acc, { ...curr }]
      else return [...acc]
    }, [])
}

export const apptList = (patient, nextVisit, appointmentToResched) => {
  if (patient.id) {
    const nextVisitsArr = []
    if (appointmentToResched) nextVisitsArr.push(nextVisit)
    else
      patient.next_visits
        .filter(
          visit => !visit.booked && isUpcoming(
            visit.date_specified, visit.window
          )
        ).forEach((item) => {
          if (item['available_for_pp?']) nextVisitsArr.push(item)
        })
    return nextVisitsArr
  } else {
    return []
  }
}

export const locList = (practice) =>
  filterInactiveLocations(practice.locations).reduce(
    (acc, curr) => [
      ...acc,
      {
        label: `${curr.name}`,
        ...curr,
      },
    ],
    []
  )

export const findSchedItem = (eventVal, selectorList, attribute) => {
  if (selectorList)
    return selectorList.find((item) => item[attribute] === eventVal)
}

export const selectorList = (
  patients,
  practice,
  patient,
  nextVisit,
  location,
  appointmentToResched
) => [
  {
    id: 1,
    label: 'Choose a patient',
    type: 'patientName',
    currentSelected: patient.id,
    list: patientList(patients, appointmentToResched),
  },
  {
    id: 2,
    label: 'Choose an Appointment',
    type: 'apptTypeDate',
    currentSelected: nextVisit.id,
    list: apptList(patient, nextVisit, appointmentToResched),
  },
  {
    id: 3,
    label: 'Choose a Location',
    type: 'location',
    currentSelected: location.id,
    list: locList(practice),
  },
]
