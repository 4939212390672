import React from 'react'
import PropTypes from 'prop-types'
import styles from './patientInfo.module.css'

const PatientInfoBox = ({ title, body }) => {
  return (
    <>
      {body && (
        <div className={styles.infoBox}>
          <h5>{title}</h5>
          <div className={styles.infoBoxBody}>
            <span className='pre-wrap-white-space'>{body}</span>
          </div>
        </div>
      )}
    </>
  )
}

PatientInfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default PatientInfoBox
