import * as ACCT from '../reducerTypes'

export default {
  [ACCT.MFA]: (state, action) => ({
    ...state,
    MFAType: action.value,
  }),
  [ACCT.SET_LEGAL_AGREED]: state => ({
    ...state,
    legalAgreed: !state.legalAgreed,
  }),
  [ACCT.PRACTICE]: (state, action) => ({
    ...state,
    practice: action.value,
  }),
  [ACCT.PATIENTS]: (state, action) => ({
    ...state,
    patients: action.value,
  }),
}
