import React from 'react'
import { Person, Phone, Map } from '@material-ui/icons'
import { cardData } from './utils'
import styles from './portal.module.css'
import { formatPhoneNumberDotSpacing } from '../../services/phoneNumberFormatters'

export default function AppointmentCardContent(props) {
  const { appointment, practice } = props

  const apptData = cardData(practice, appointment)

  const niceNumber = formatPhoneNumberDotSpacing(apptData.phone)

  const tiles = [
    {
      id: 'doctor',
      icon: <Person />,
      info: apptData.doctorName,
    },
    {
      id: 'location',
      icon: <Map />,
      info: apptData.address,
    },
    {
      id: 'phone',
      icon: <Phone />,
      info: niceNumber,
    },
  ]

  const tileListWithData = () => {
    const tilesList = []
    tiles.forEach((item) => {
      if (item.info) tilesList.push(item)
    })
    return tilesList
  }

  return (
    <div className={styles.cardColCont}>
      {tileListWithData().map((tile) =>
        <div key={tile.id} className={styles.apptCardInfo}>
          {tile.icon}
          <span className={styles.apptCardText}>
            {tile.info}
          </span>
        </div>
      )}
    </div>
  )
}
