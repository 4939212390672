import React, { useState } from 'react'
import LegalPopover from './LegalPopover'
import { Checkbox } from '@material-ui/core/'
import styles from './auth.module.css'
import { useGlobalState } from '../../state'

export default function TermsCheckbox() {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [{ legalAgreed }, dispatch] = useGlobalState()

  function handleDialogOpen() {
    setDialogOpen(true)
  }

  function handleDialogClose() {
    setDialogOpen(false)
  }

  const handleAgree = () => {
    dispatch({
      type: 'toggleLegalAgreed',
    })
    handleDialogClose()
  }

  return (
    <div className={styles.termsCheckbox}>
      <Checkbox
        checked={legalAgreed}
        onChange={handleAgree}
        className={styles.legalCheckBox}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <p>
        I have read, and agree to the{' '}
        <span className={styles.legalToggle} onClick={handleDialogOpen}>
          Terms of Service{' '}
        </span>
      </p>
      <LegalPopover
        title="Terms of Service"
        dialogOpen={dialogOpen}
        handleDialogClose={handleDialogClose}
        legalAgreed={legalAgreed}
        handleAgree={handleAgree}
      />
    </div>
  )
}
