import React from 'react'
import PropTypes from 'prop-types'
import styles from './patientInfo.module.css'
import PatientInfoBox from './PatientInfoBox'

const PatientInfoColumn = ({ fields }) => (
  <div className={styles.infoColumn}>
    <div className={styles.infoColumnTitle}>
      <h3>Patient Information</h3>
    </div>
    {fields.map(field => (
      <PatientInfoBox key={field.title} title={field.title} body={field.body} />
    ))}
  </div>
)

PatientInfoColumn.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ),
}

export default PatientInfoColumn
