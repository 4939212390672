import moment from 'moment-timezone'

export function formatDatePickerMonth(date) {
  const calculateMonthsAndYears = () => {
    const monthArr = []
    const yearArr = []
    let i
    for (let day = 0; day < 7; day++) {
      i = date.clone().day(day)
      let month = i.format('MMMM')
      let year = i.format('YYYY')
      if (!monthArr.includes(month)) monthArr.push(month)
      if (!yearArr.includes(year)) yearArr.push(year)
    }
    return formatMonthsAndYears(monthArr, yearArr)
  }

  const formatMonthsAndYears = (monthArr, yearArr) => {
    if (monthArr.length === 1) {
      return `${monthArr[0]} ${yearArr[0]}`
    } else if (yearArr.length === 2) {
      return `${monthArr[0]} ${yearArr[0]} - ${monthArr[1]} ${yearArr[1]}`
    } else {
      return `${monthArr[0]} - ${monthArr[1]} ${yearArr[0]}`
    }
  }

  return calculateMonthsAndYears()
}

export const createDateArr = (date, dateToAdd) => {
  const dateArr = []
  for (let day = 0; day < dateToAdd; day++) {
    dateArr.push(
      date
        .clone()
        .day(day)
        .format('ddd D MMM YYYY')
    )
  }
  return dateArr
}

export const sortBookings = (bookings) => {
  const bookingsObj = {}
  bookings.forEach((b) => {
    const tempDate = moment(b.start_datetime).format('ddd D MMM YYYY')
    if (!!bookingsObj[tempDate]) bookingsObj[tempDate].push(b)
    else bookingsObj[tempDate] = [b]
  })
  return bookingsObj
}
