import * as AUTH from '../reducerTypes'

export default {
  [AUTH.AUTHENTICATED]: (state, action) => ({
    ...state,
    authenticated: action.value,
  }),
  [AUTH.AUTHENTICATING]: (state, action) => ({
    ...state,
    authenticating: action.value,
  }),
  [AUTH.LOADING]: (state, action) => ({
    ...state,
    loading: action.value,
  }),
  [AUTH.EMAIL]: (state, action) => ({
    ...state,
    email: action.value,
  }),
  [AUTH.CODE]: (state, action) => ({
    ...state,
    code: action.value,
  }),
  [AUTH.PASSWORD]: (state, action) => ({
    ...state,
    password: action.value,
  }),
  [AUTH.NEW_PASSWORD]: (state, action) => ({
    ...state,
    newPassword: action.value,
  }),
  [AUTH.PASS_VALIDITY]: (state, action) => ({
    ...state,
    passValidity: action.value,
  }),
  [AUTH.LOGIN]: (state, action) => ({
    ...state,
    user: action.value,
    authenticated: true,
    authenticating: true,
    code: '',
    email: '',
    legalAgreed: false,
    newPassword: '',
    passValidity: {
      isLongEnough: false,
      hasUpperAndLower: false,
      hasNumbers: false,
      hasSpecial: false,
    },
    password: '',
  }),
  [AUTH.INITIAL_DATA]: (state, action) => ({
    ...state,
    authenticated: action.value.authenticated,
    authenticating: action.value.authenticating,
    user: action.value.user,
    practice: action.value.practice,
    patients: action.value.patients,
  }),
  [AUTH.CLEAR_AUTH_STATE]: (state) => ({
    ...state,
    code: '',
    email: '',
    legalAgreed: false,
    newPassword: '',
    passValidity: {
      isLongEnough: false,
      hasUpperAndLower: false,
      hasNumbers: false,
      hasSpecial: false,
    },
    password: '',
  }),
  [AUTH.SIGN_OUT]: (state) => ({
    ...state,
    authenticated: false,
    authenticating: true,
    loading: false,
    user: null,
    practice: null,
    practiceApptTypes: null,
    patients: null,
    email: '',
    code: '',
    activePatient: null,
    calendarView: false,
    password: '',
    newPassword: '',
    nextVisitToSchedule: {
      patient: { id: '', label: '' },
      nextVisit: { id: '', label: '' },
      location: { id: '', label: '' },
    },
    apptToReschedule: null,
    passValidity: {
      isLongEnough: false,
      hasUpperAndLower: false,
      hasNumbers: false,
      hasSpecial: false,
    },
    MFAType: null,
    legalAgreed: false,
    bookings: {},
    selectedBooking: null,
  }),
}
