import account from './account'
import auth from './auth'
import schedule from './schedule'

export const appReducer = (state, action) => {
  const reducers = {
    ...account,
    ...auth,
    ...schedule,
  }
  return !!reducers[action.type] ? reducers[action.type](state, action) : state
}
