import React from 'react'
import { Auth } from 'aws-amplify'
import Header from './Header'
import AuthInputField from './AuthInputField'
import Footer from './Footer'
import WelcomePage from './WelcomePage'
import styles from './auth.module.css'
import { useGlobalState } from '../../state'
import {
  emailCheck,
  codeCheck,
  passwordCheck,
  createUser,
  deleteUser,
} from '../../services/fetchHelpers'
import { emailValidator } from '../../services/authHelpers'

export default function SignUp(props) {
  const {
    authErrors,
    handleClearErrors,
    history,
    navigateNextPage,
    navigatePrevPage,
    pageNum,
    setAuthErrors,
    setPageNum,
  } = props
  const [
    {
      email,
      code,
      password,
      subdirectory,
    },
    dispatch,
  ] = useGlobalState()

  const errorHandler = errorMsg => {
    setAuthErrors(errorMsg)
  }

  const handleEmailSubmit = async () => {
    try {
      if (emailValidator(email)) {
        await emailCheck(email)
        navigateNextPage()
      } else {
        errorHandler('Invalid email format or character')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleResendEmail = () => emailCheck(email)

  const handleCodeSubmit = async () => {
    try {
      await codeCheck(email, code)
      navigateNextPage()
    } catch {
      errorHandler(`This code is incorrect`)
    }
  }

  const handleRegister = async () => {
    const passResponse = await passwordCheck(password)
    if (passResponse['isPwned']) {
      errorHandler(
        `This password is too common, you should change it wherever it is used`
      )
    } else {
      const userResponse = await createUser(email)
      if (userResponse['status'] === 'ok') {
        try {
          await Auth.signUp({
            username: email,
            password,
            attributes: {
              email: email,
            },
          })
          navigateNextPage()
        } catch (error) {
          await deleteUser(email)
          errorHandler(error.message)
        }
      } else {
        errorHandler('There was an error. Try again!')
      }
    }
  }

  const pages = [
    {
      type: 'email',
      input: (
        <AuthInputField
          type="email"
          authErrors={authErrors}
          value={email}
          handleSubmit={handleEmailSubmit}
        />
      ),
      handleNext: handleEmailSubmit,
      handlePrev: null,
      headerText: 'hello.',
      buttonText: 'Next',
    },
    {
      type: 'code',
      input: (
        <AuthInputField
          type="code"
          authErrors={authErrors}
          value={code}
          handleResendEmail={handleResendEmail}
          handleSubmit={handleCodeSubmit}
        />
      ),
      handleNext: handleCodeSubmit,
      handlePrev: navigatePrevPage,
      headerText: 'check your email!',
      buttonText: 'Next',
    },
    {
      type: 'password',
      input: (
        <AuthInputField
          type="password"
          authErrors={authErrors}
          value={password}
          validate={true}
          handleSubmit={handleRegister}
        />
      ),
      handleNext: handleRegister,
      handlePrev: navigatePrevPage,
      headerText: 'make a password.',
      buttonText: 'Next',
    },
    {
      type: null,
      input: <WelcomePage history={history} />,
      handleNext: null,
      handlePrev: null,
      headerText: 'yay!',
      buttonText: null,
    },
  ]

  const textObject = {
    text: 'have an account? sign in.',
    onClick: () => {
      dispatch({ type: 'clearAuthState' })
      handleClearErrors()
      history.push(`${subdirectory}login`)
    },
  }

  return (
    <div className={styles.authPage}>
      <Header text={pages[pageNum].headerText} />
      {pages[pageNum].input}
      <Footer
        type={pages[pageNum].type}
        pageNum={pageNum}
        setPageNum={setPageNum}
        isSignUp={true}
        handleNext={pages[pageNum].handleNext}
        handlePrev={pages[pageNum].handlePrev}
        buttonText={pages[pageNum].buttonText}
        textObject={textObject}
        numPages={pages.length}
      />
    </div>
  )
}
