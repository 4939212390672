import React from 'react'
import moment from 'moment-timezone'
import { addressFormatter } from '../../services/utils'
import { useGlobalState } from '../../state'
import styles from './patientInfo.module.css'
import PatientInfoColumn from './PatientInfoColumn'
import UpdateInfoColumns from './UpdateInfoColumns'

export default function PatientInfo() {
  const [
    {
      activePatient,
      activePatient: { doctor_id: doctorId, birthday, id },
      nextVisitToSchedule,
      practice: {
        cloud9_use_correspondence_values: cloud9UseCorrespondenceValues,
        doctors,
        locations,
        products,
      },
      user: { attributes: { email: acctEmail } }
    },
    dispatch,
  ] = useGlobalState()
  const patientOrthodontist = doctors.find(doc => doc.id === doctorId).last_name

  const patBday = moment(birthday)

  const fields = [
    {
      title: 'Mailing Address',
      body: `${addressFormatter(activePatient)}`,
    },
    {
      title: 'Primary Orthodontist',
      body: `Dr. ${patientOrthodontist}`,
    },
    {
      title: 'Date of Birth',
      body: `${patBday.format('MMM. Do, YYYY')}`,
    },
  ]

  const productNames = products.map(product => product.name)

  return (
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        <PatientInfoColumn fields={fields} />
        <UpdateInfoColumns
          activePatient={activePatient}
          acctEmail={acctEmail}
          id={id}
          cloud9UseCorrespondenceValues={cloud9UseCorrespondenceValues}
          dispatch={dispatch}
          locations={locations}
          nextVisitToSchedule={nextVisitToSchedule}
          products={productNames}
        />
      </div>
    </div>
  )
}
