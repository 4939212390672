import React from 'react'
import BookingsColumn from './BookingsColumn'
import styles from '../schedule.module.css'

export default function BookingsContainer(props) {
  const { dateArr, bookings, handleSelectBooking, selectedBooking } = props

  const maxLength = () => {
    let maxLen = 0
    for (let day in bookings) {
      let len = bookings[day].length
      if (len > maxLen) maxLen = len
    }
    return maxLen
  }

  const mappedBookings = () => {
    const len = maxLength()
    const dateBookings = {}
    dateArr.forEach((date) => {
      for(let i = 0; i <= len; i++) {
        if (i === 0) {
          dateBookings[date] = [date]
        } else {
          if (bookings[date] !== undefined && bookings[date][i - 1] !== undefined) {
            dateBookings[date].push(bookings[date][i-1])
          } else {
            dateBookings[date].push('--')
          }
        }
      }
    })
    return dateBookings
  }

  return (
    <div className={styles.bookingsContainer}>
      {
        dateArr.map((date) => {
          return (
            <BookingsColumn 
              data={mappedBookings()[date]}
              key={date}
              handleSelectBooking={handleSelectBooking}
              selectedBooking={selectedBooking}
            />
          )
        })
      }

    </div>
  )
}
