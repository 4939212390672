import React from 'react'
import cx from 'classnames'
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core'
import styles from './schedule.module.css'

export default function ScheduleSelector({
  currentSelected,
  handleChange,
  label,
  list,
  recommendedDate,
  type,
}) {
  const menuItem = (item) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.label}
      </MenuItem>
    )
  }

  const menuProps = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  }

  const dateClasses = cx(styles.scheduleApptTime, {
    [styles.scheduleApptTimeShowing]:
      type === 'apptTypeDate' && currentSelected,
  })

  return (
    <FormControl className={styles.scheduleSelect} disabled={!list.length}>
      <InputLabel shrink>{label}</InputLabel>
      <Select
        value={currentSelected}
        MenuProps={menuProps}
        onChange={(event) => handleChange(event, type, list)}
        displayEmpty
        className={styles.selectEmpty}
      >
        {list.map(menuItem)}
      </Select>
      <div className={dateClasses}>
        {`Recommended Date: `}
        <span
          className={styles.scheduleApptTimeDate}
        >{`${recommendedDate}`}</span>
      </div>
    </FormControl>
  )
}
