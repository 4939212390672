import React, { useState, useRef, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import styles from './settings.module.css'
import { useAuthHandleChange } from '../../services/authHelpers'
import PasswordVisibility from '../../services/PasswordVisibility'

export default function UpdateInfoInput(props) {
  const [showPassword, setShowPassword] = useState(false)

  const {
    inputField: { type, actionType, validate, value, label, handleSubmit },
  } = props
  const inputEl = useRef(null)

  const handleChange = useAuthHandleChange()

  useEffect(() => {
    const inputContentLen = inputEl.current.value.length
    inputEl.current.selectionStart = inputContentLen
  }, [showPassword])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleEnterKey = (event) => {
    if (event.keyCode === 13) handleSubmit()
  }

  const handleMouseDownShowPassword = (event) => {
    event.preventDefault()
  }

  const capitalize = (str) => {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const isPassword = () => {
    return type === 'password'
  }

  return (
    <div>
      <TextField
        id={label}
        inputRef={inputEl}
        className={styles.changePassInput}
        type={isPassword() && !showPassword ? 'password' : 'text'}
        label={capitalize(label)}
        name={label}
        value={value}
        onKeyUp={(event) => handleEnterKey(event)}
        onChange={(event) => handleChange(event, actionType, validate)}
        InputProps={
          isPassword() && {
            endAdornment: (
              <PasswordVisibility
                showPassword={showPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownShowPassword={handleMouseDownShowPassword}
              />
            ),
          }
        }
      />
    </div>
  )
}
