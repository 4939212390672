import React from 'react'
import { Button } from '@material-ui/core'

import styles from './settings.module.css'
import UpdateInfoInput from './UpdateInfoInput'
import PasswordValidations from '../../services/PasswordValidations'

export default function ChangePassword(props) {
  const { updateToNewPass, password, newPassword, passwordChangeStatus } = props

  const updatePasswordFields = [
    {
      type: 'password',
      label: 'Old Password',
      validate: false,
      actionType: 'password',
      value: password,
      isChangePassword: false,
      handleSubmit: updateToNewPass,
    },
    {
      type: 'password',
      label: 'New Password',
      validate: true,
      actionType: 'newPassword',
      value: newPassword,
      isChangePassword: true,
      handleSubmit: updateToNewPass,
    },
  ]

  return (
    <div className={styles.flexContainer}>
      <div className={styles.halfWidthContainer}>
        {updatePasswordFields.map((inputField) => (
          <UpdateInfoInput
            key={inputField.label}
            inputField={inputField}
            handleSubmit={updateToNewPass}
          />
        ))}
        <Button
          className={styles.appButton}
          color="primary"
          disabled={!(newPassword && password)}
          disableElevation
          fullWidth
          onClick={updateToNewPass}
          variant="contained"
        >
          Change Password
        </Button>
      </div>
      <div className={styles.halfWidthContainer}>
        {passwordChangeStatus ? (
          <p>{passwordChangeStatus}</p>
        ) : (
          <PasswordValidations />
        )}
      </div>
    </div>
  )
}
