import React from 'react'
import Button from '@material-ui/core/Button'
import styles from './auth.module.css'
import { useGlobalState } from '../../state'

export default function WelcomePage(props) {
  const { history } = props
  const [{ subdirectory }] = useGlobalState()

  const textObject = {
    text: 'back to login',
    onClick: () => history.push(`${subdirectory}login`),
  }

  return (
    <div className={styles.footer}>
      <h5 className={styles.authSubtext}>
        you've successfully created your account!
      </h5>
      <Button
        className={styles.appButton}
        color='primary'
        fullWidth
        disableElevation
        onClick={() => textObject.onClick()}
        variant="contained"
      >
        {textObject.text}
      </Button>
    </div>
  )
}
