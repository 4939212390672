import React from 'react'
import styles from './services.module.css'
import { CircularProgress } from '@material-ui/core'

export default function Spinner (props) {
  const { absolute } = props

  
  
  return (
    <div className={absolute ? styles.spinnerContAbsolute : styles.spinnerContainer}>
      <CircularProgress />
    </div>
  )
}
