import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import SignUp from './SignUp'
import LogIn from './LogIn'
import ForgotPassword from './ForgotPassword'
import { useGlobalState } from '../../state'
import { passwordCheck } from '../../services/fetchHelpers'
import { reducerTypeMapping, emailValidator } from '../../services/authHelpers'

export default function AuthFlow(props) {
  const { authType, history } = props
  const [
    {
      email,
      code,
      password,
      subdirectory,
    },
    dispatch,
  ] = useGlobalState()
  const [authErrors, setAuthErrors] = useState(null)
  const [pageNum, setPageNum] = useState(0)

  const setGlobalState = (type, value) => {
    dispatch({
      type: type,
      value: value,
    })
  }

  const handleClearErrors = () => {
    setAuthErrors(null)
    const validity = {
      isLongEnough: false,
      hasUpperAndLower: false,
      hasNumbers: false,
      hasSpecial: false,
    }
    setGlobalState('setPassValidity', validity)
  }

  const navigateNextPage = () => {
    setPageNum(pageNum + 1)
    handleClearErrors()
  }

  const navigatePrevPage = type => {
    setPageNum(pageNum - 1)
    handleClearErrors()
    setGlobalState(reducerTypeMapping[type], '')
  }

  const handleLogin = async () => {
    try {
      if (emailValidator(email)) {
        const userData = await Auth.signIn(email, password)
        setGlobalState('login', userData)
        history.push(`${subdirectory}`)
      } else {
        setAuthErrors('Invalid email format or characters')
      }
    } catch (error) {
      setAuthErrors(error.message)
    }
  }

  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(email)
    } catch (error) {
      setAuthErrors(error.message)
    }
  }

  const handleForgotPasswordVerification = async () => {
    const passResponse = await passwordCheck(password)
    if (passResponse['isPwned']) {
      setAuthErrors(
        `This password is too common, you should change it wherever it is used`
      )
      return false
    } else {
      try {
        await Auth.forgotPasswordSubmit(email, code, password)
        return true
      } catch (error) {
        setAuthErrors(error.message)
      }
    }
  }

  switch (authType) {
    case 'signup':
      return (
        <SignUp
          authErrors={authErrors}
          handleClearErrors={handleClearErrors}
          handleForgotPassword={handleForgotPassword}
          history={history}
          navigateNextPage={navigateNextPage}
          navigatePrevPage={navigatePrevPage}
          pageNum={pageNum}
          setAuthErrors={setAuthErrors}
          setGlobalState={setGlobalState}
          reducerTypeMapping={reducerTypeMapping}
        />
      )
    case 'login':
      return (
        <LogIn
          authErrors={authErrors}
          history={props.history}
          handleLogin={handleLogin}
        />
      )
    case 'forgotPassword':
      return (
        <ForgotPassword
          authErrors={authErrors}
          handleClearErrors={handleClearErrors}
          handleForgotPassword={handleForgotPassword}
          handleForgotPasswordVerification={handleForgotPasswordVerification}
          history={history}
          navigateNextPage={navigateNextPage}
          navigatePrevPage={navigatePrevPage}
          pageNum={pageNum}
          reducerTypeMapping={reducerTypeMapping}
          setAuthErrors={setAuthErrors}
          setGlobalState={setGlobalState}
        />
      )
    default:
      return null
  }
}
