import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import { useGlobalState } from '../state'
import styles from './services.module.css'

export default function PasswordValidations(props) {
  const [{ passValidity }] = useGlobalState()
  const {
    isLongEnough,
    hasUpperAndLower,
    hasNumbers,
    hasSpecial,
  } = passValidity

  const validationList = [
    {
      validationType: isLongEnough,
      message: 'Use 8 or more characters',
    },
    {
      validationType: hasUpperAndLower,
      message: 'Use upper and lower case letters (e.g. Aa)',
    },
    {
      validationType: hasNumbers,
      message: 'Use a number (e.g. 1234)',
    },
    {
      validationType: hasSpecial,
      message: 'Use a symbol (e.g. !@#$)',
    },
  ]

  const isValid = () => {
    return Object.values(passValidity).every((test) => test)
  }

  return (
    <div>
      {isValid() ? (
        <List dense={true}>
          <ListItem>
            <ListItemIcon className={styles.successIcon}>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary="All requirements met" />
          </ListItem>
        </List>
      ) : (
        <List dense={true}>
          {validationList.map((validation) => (
            <ListItem key={validation.message}>
              <ListItemIcon
                className={
                  validation.validationType
                    ? styles.successIcon
                    : styles.dangerIcon
                }
              >
                {validation.validationType ? <CheckIcon /> : <CloseIcon />}
              </ListItemIcon>
              <ListItemText primary={validation.message} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}
