import React from 'react'
import { Auth } from 'aws-amplify'
import { Settings, ExitToApp } from '@material-ui/icons'
import cx from 'classnames'
import moment from 'moment-timezone'
import { useGlobalState } from '../../state'
import styles from './navigation.module.css'
import NavigationLink from './NavigationLink'
import { subdirectoryStorage, tokenStorage } from '../../services/localStorage'

export default function NavigationTop() {
  const [{ activePatient, subdirectory }, dispatch] = useGlobalState()

  const handleSignOut = async () => {
    try {
      await Auth.signOut({ global: true })
      tokenStorage.clear()
      subdirectoryStorage.clear()
      dispatch({ type: 'signOut' })
    } catch (error) {
      console.error(error)
    }
  }

  const isScheduleDisabled =
    activePatient &&
    !activePatient.next_visits.some(
      ({ booked, date_specified: dateSpecified, window }) =>
        !booked &&
        moment().isBefore(moment(dateSpecified).add(window + 1, 'weeks'))
    )

  const classes = cx(styles.patientTool, {
    [`${styles.patientToolDisabled}`]: isScheduleDisabled,
  })

  const allAppts = <h6>All Appointments</h6>
  const schedNow = <h6>Schedule Now</h6>
  const patInfo = <h6>Patient Info</h6>
  const settings = <Settings fontSize='small' />
  const logOut = <ExitToApp fontSize='small' />

  return (
    <div className={styles.navContainer}>
      <div>
        <NavigationLink
          active
          content={allAppts}
          navClassName={styles.patientTool}
          styles={styles}
          to={`${subdirectory}`}
        />
        <NavigationLink
          active
          content={schedNow}
          navClassName={classes}
          styles={styles}
          to={`${subdirectory}schedule`}
        />
        {activePatient && (
          <NavigationLink
            active
            content={patInfo}
            navClassName={styles.patientTool}
            styles={styles}
            to={`${subdirectory}info`}
          />
        )}
      </div>
      <div>
        <NavigationLink
          content={settings}
          isIconButton
          navClassName={styles.navTool}
          styles={styles}
          to={`${subdirectory}settings`}
          toolTipTitle="settings"
        />
        <NavigationLink
          content={logOut}
          isIconButton
          navClassName={styles.navTool}
          onClick={handleSignOut}
          styles={styles}
          to={`${subdirectory}login`}
          toolTipTitle="log out"
        />
      </div>
    </div>
  )
}
