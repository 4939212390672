import React from 'react'
import { Button } from '@material-ui/core'
import styles from '../schedule.module.css'
import { useGlobalState } from '../../../state'

export default function DialogFooter({ handleConfirm }) {
  const [
    {
      practice: { allow_rescheduling },
    },
  ] = useGlobalState()

  return (
    <div className={styles.dialogFooter}>
      {!allow_rescheduling && (
        <div className={styles.dialogFooterText}>
          <p>
            Note: once the appointment is confirmed, you must call the office if
            you would like to reschedule.
          </p>
        </div>
      )}
      <Button
        className={styles.button}
        color="primary"
        key="confirm"
        onClick={handleConfirm}
        variant="contained"
      >
        Confirm
      </Button>
    </div>
  )
}
