import React from 'react'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core/'
import TermsOfUse from './TermsOfUse'

export default function LegalPopover(props) {
  const {
    title,
    dialogOpen,
    handleDialogClose,
    legalAgreed,
    handleAgree,
  } = props

  return (
    <div className="legalStuff">
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {TermsOfUse()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                checked={legalAgreed}
                onChange={handleAgree}
                color="primary"
                value="agree"
              />
            }
            label="I Agree to the Terms of Service."
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}
