import moment from 'moment-timezone'
import { findSubdirectory } from '../services/utils.js'

export const initialState = {
  activePatient: null,
  apptToReschedule: null,
  authenticated: false,
  authenticating: true,
  bookings: {},
  code: '',
  calendarView: false,
  date: moment(),
  email: '',
  legalAgreed: false,
  loading: false,
  MFAType: null,
  newPassword: '',
  nextVisitToSchedule: {
    patient: { id: '', label: '' },
    nextVisit: { id: '', label: '' },
    location: { id: '', label: '' },
  },
  passValidity: {
    isLongEnough: false,
    hasUpperAndLower: false,
    hasNumbers: false,
    hasSpecial: false,
  },
  password: '',
  patients: null,
  practice: null,
  practiceApptTypes: null,
  selectedBooking: null,
  subdirectory: findSubdirectory(),
  user: null,
}
