import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import UpdateInfoBox from './UpdateInfoBox'
import styles from './patientInfo.module.css'

const UpdateContacts = ({ fieldsToUpdate, isDisabled, onSubmit }) => {
  return (
    <>
      {fieldsToUpdate.map(
        ({ errors, inputRef, label, name, onChange, value }) => (
          <UpdateInfoBox
            errors={errors}
            inputRef={inputRef}
            key={name}
            label={label}
            name={name}
            onChange={onChange}
            value={value}
          />
        ),
      )}
      <Button
        className={styles.button}
        fullWidth
        disableElevation
        onClick={() => onSubmit()}
        variant='contained'
        color='primary'
        disabled={isDisabled}
      >
        Submit
      </Button>
    </>
  )
}

UpdateContacts.propTypes = {
  fieldsToUpdate: PropTypes.arrayOf(
    PropTypes.shape({
      errors: PropTypes.bool,
      inputRef: PropTypes.oneOfType([
        // Either a function
        PropTypes.func,
        // Or the instance of a DOM native element (see the note about SSR)
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
      ]),
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default UpdateContacts
