import React from 'react'
import ScheduleSetPage from './ScheduleSetPage'
import BookingsCalendar from './BookingsCalendar'
import styles from './schedule.module.css'
import { useGlobalState } from '../../state'

export default function Schedule(props) {
  const { history } = props
  const [{ loading, calendarView }] = useGlobalState()

  return (
    <div className={styles.contentContainer}>
      <div>
        {!loading && (
          <div>
            {calendarView ? (
              <BookingsCalendar history={history} />
            ) : (
              <ScheduleSetPage />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
