import moment from 'moment-timezone'

export const findSubdirectory = () => {
  const urlArr = window.location.href.split('/').filter(str => str !== '')
  const ind = urlArr.findIndex(
    str => str === process.env.REACT_APP_SITE_BASE_URL,
  )

  if (ind === -1 || ind === urlArr.length - 1) return '/'
  const dir = urlArr[ind + 1]
  const allowable = [
    'signup',
    'login',
    'forgotpassword',
    'settings',
    'schedule',
    'info',
  ]
  if (allowable.includes(dir)) return '/'
  return `/${dir}/`
}

export const dateCompare = date => {
  return moment(date).format() <= moment(new Date()).add(28, 'weeks').format()
}

export const filterPatientNextVisits = patients => {
  const patientsFiltered = patients.map(patient => {
    const {
      first_name: firstName,
      last_name: lastName,
      preferred_name: preferredName,
    } = patient
    patient.next_visits = patient.next_visits
      .filter(visit => dateCompare(visit.date_specified))
      .sort((a, b) => moment(a.date_specified).diff(moment(b.date_specified)))
    patient.label = `${preferredName ? preferredName : firstName} ${lastName}`
    patient.next_visits.map(nv => {
      nv.label = nv.appointment_type_description
      return nv
    })
    return patient
  })
  return patientsFiltered
}

export const findPatientLocation = (patientToUpdate, locations) => {
  const patientAppts = patientToUpdate.appointments_for_pp
  const patientLocation = patientAppts.length
    ? patientAppts[patientAppts.length - 1].location_id
    : patientToUpdate.location_id
  const filteredLocations = filterInactiveLocations(locations)
  const location = filteredLocations.find(
    location => location.id === patientLocation,
  )
  const selectedLocation = location ? location : filteredLocations[0]
  selectedLocation.label = selectedLocation.name
  return selectedLocation
}

export const isUpcoming = (date, window, existingAppt = false) =>
  date &&
  moment(date).isAfter(
    existingAppt
      ? moment(new Date())
      : moment(new Date()).subtract(window, 'weeks'),
  )

export const filterInactiveLocations = locations =>
  locations.filter(loc => loc.active)

export const addressFormatter = location =>
  `${location.street_address ? location.street_address + ',\n' : ''}${
    location.secondary_address ? location.secondary_address + ',\n' : ''
  }${location.city ? location.city + ', ' : ''}${
    location.state ? location.state + ',\n' : ''
  }${location.zip ? location.zip : ''}`

  export const patientHasNextVisits = patientToUpdate =>
    patientToUpdate &&
    patientToUpdate.next_visits.filter(visit => !visit.booked).length
  
  export const updateNextVisit = patientToUpdate => {
    const currNextVisit = patientToUpdate.next_visits.find(
      visit => !visit.booked && isUpcoming(visit.date_specified, visit.window),
    )
    if (!!currNextVisit) {
      currNextVisit.label = currNextVisit.appointment_type_description
      return currNextVisit
    } else {
      return { label: '' }
    }
  }
  
  export const updatePatient = patientToUpdate => {
    const {
      first_name: firstName,
      last_name: lastName,
      preferred_name: preferredName,
    } = patientToUpdate
    patientToUpdate.label = `${
      preferredName ? preferredName : firstName
    } ${lastName}`
    return patientToUpdate
  }
  
  export const updateNextVisitToSched = (patientToUpdate, locations) => {
    if (patientHasNextVisits(patientToUpdate)) {
      return {
        patient: updatePatient(patientToUpdate),
        nextVisit: updateNextVisit(patientToUpdate),
        location: findPatientLocation(patientToUpdate, locations),
      }
    } else {
      return {
        patient: { id: '', label: '' },
        nextVisit: { id: '', label: '' },
        location: { id: '', label: '' },
      }
    }
  }
