import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import ChangePassword from './ChangePassword'
import styles from './settings.module.css'
import { useGlobalState } from '../../state'

export default function Settings() {
  const [{ password, newPassword, subdirectory }, dispatch] = useGlobalState()

  const [passwordChangeStatus, setPasswordChangeStatus] = useState(null)

  useEffect(() => {
    setPasswordChangeStatus(null)
  }, [password, newPassword])

  const updateToNewPass = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, password, newPassword)
      setPasswordChangeStatus('Password Successfully Changed')
      dispatch({ type: 'clearAuthState' })
    } catch (error) {
      setPasswordChangeStatus(error.message)
    }
  }

  return (
    <div className={styles.contentContainer}>
      <div>
        <Button
          className={styles.settingBackButton}
          color="primary"
          component={NavLink}
          to={`${subdirectory}`}
          disableElevation
          variant="contained"
        >
          Back
        </Button>
        <h4 className={styles.settingsTitle}>Settings</h4>
        <div className={styles.fullWidth}>
          <ChangePassword
            updateToNewPass={updateToNewPass}
            password={password}
            newPassword={newPassword}
            passwordChangeStatus={passwordChangeStatus}
          />
        </div>
      </div>
    </div>
  )
}
