import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

const RescheduleGuardDialog = ({
  handleClose,
  handleConfirm,
  message,
  showConfirm,
  to,
}) => {
  return (
    <Dialog
      open={showConfirm}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {message}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          component={NavLink}
          onClick={handleConfirm}
          to={to}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RescheduleGuardDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
}

export default RescheduleGuardDialog
