import moment from 'moment-timezone'
import { isUpcoming } from '../../services/utils'

export const shapeNextVisitToSched = (patient, appointment, locations) => {
  const {
    appointments_for_pp: appointmentsForPp,
    first_name: firstName,
    last_name: lastName,
    location_id: locationId,
    preferred_name: preferredName,
  } = patient
  patient.label = `${preferredName ? preferredName : firstName} ${lastName}`
  appointment.label = appointment.appointment_type_description
  const location = locations.find(location => {
    const patientAppts = appointmentsForPp
    return patientAppts.length
      ? location.id === patientAppts[patientAppts.length - 1].location_id
      : location.id === locationId
  })
  location.label = location.name
  return {
    patient: patient,
    nextVisit: appointment,
    location: location,
  }
}

export const apptTimeDate = (appointment, apptDateType) => {
  return appointment && appointment[apptDateType]
    ? {
        hours: moment(appointment[apptDateType]).format('LT'),
        date: moment(appointment[apptDateType]).format('ddd | LL'),
      }
    : 'No time specified'
}

const validAppt = (date, apptVisitType, booked, window) => {
  const isScheduled = apptVisitType === 'appointments_for_pp'
  return isUpcoming(date, window, isScheduled) && (isScheduled || !booked)
}

export const singlePatientApptFilter = (
  activePatient,
  apptVisitType,
  apptDate,
) => {
  const {
    first_name: firstName,
    preferred_name: preferredName,
  } = activePatient
  const apptArr = activePatient[apptVisitType]
    .filter(
      appt => validAppt(appt[apptDate], apptVisitType, appt.booked, appt.window)
    ).slice(0, 4)
  const patientsNoAppts = apptArr.length
    ? []
    : [preferredName ? preferredName : firstName]
  return { apptArr, patientsNoAppts }
}

export const multiPatientApptFilter = (patients, apptVisitType, apptDate) => {
  const apptArr = []
  const patientsNoAppts = []
  patients.forEach(patient => {
    const {
      first_name: firstName,
      preferred_name: preferredName,
    } = patient
    const selected = patient[apptVisitType].find(appt =>
      validAppt(appt[apptDate], apptVisitType, appt.booked, appt.window),
    )
    if (selected) apptArr.push(selected)
    else patientsNoAppts.push(preferredName ? preferredName : firstName)
  })
  return { apptArr, patientsNoAppts }
}

export const disableButton = appointment => !appointment['available_for_pp?']

const findLocation = (pracLocations, apptLocation) =>
  pracLocations.find(loc => loc.id === apptLocation)

const validatePhone = currLocation =>
  currLocation ? currLocation.office_phone : 'No phone number available'

const validateAddress = currLocation => {
  const location = `
    ${currLocation.street_address ? currLocation.street_address + ',\n' : ''}
    ${
      currLocation.secondary_address
        ? currLocation.secondary_address + ',\n'
        : ''
    }
    ${currLocation.city ? currLocation.city + ', ' : ''}
    ${currLocation.state ? currLocation.state + ', ' : ''}
    ${currLocation.zip ? currLocation.zip : ''}`
  return /[A-Za-z]/.test(location) ? location : null
}

const validateDoctor = (doctors, apptDoctor) => {
  const currDoctor = doctors.find(doc => doc.id === apptDoctor)
  return currDoctor ? currDoctor.preferred_name : 'No assigned doctor'
}

export const cardData = (practice, appointment) => {
  const currLocation = findLocation(practice.locations, appointment.location_id)

  return {
    phone: validatePhone(currLocation),
    doctorName: validateDoctor(practice.doctors, appointment.doctor_id),
    address: validateAddress(currLocation),
  }
}

export const findNextVisitAppt = (appointment, patient) => {
  const possibleAppointments = patient.next_visits.filter(
    nextVisit =>
      nextVisit.appointment_type_id === appointment.appointment_type_id,
  )
  const apptDate = moment(appointment.appointment_datetime)
  const selectedNextVisit = {
    timeDiff: null,
    index: null,
  }
  possibleAppointments.forEach((nextVisit, index) => {
    const nextVisitDate = moment(nextVisit.date_specified)
    const visitTimeDifference = Math.abs(apptDate.diff(nextVisitDate))
    if (
      selectedNextVisit.timeDiff === null ||
      visitTimeDifference < selectedNextVisit.timeDiff
    ) {
      selectedNextVisit.timeDiff = visitTimeDifference
      selectedNextVisit.index = index
    }
  })
  return possibleAppointments[selectedNextVisit.index]
}
