import React from 'react'
import { Dialog, DialogContent } from '@material-ui/core/'
import DialogHeader from './DialogHeader'
import DialogBody from './DialogBody'
import DialogFooter from './DialogFooter'
import DialogSuccess from './DialogSuccess'
import styles from '../schedule.module.css'
import Spinner from '../../../services/Spinner'

export default function BookingConfirmation({
  booking,
  confirming,
  dialogOpen,
  handleDialogClose,
  handleConfirm,
  success,
  firstName,
  appointmentType,
  location,
}) {
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="scroll-dialog-title"
    >
      {confirming && <Spinner absolute />}
      {success ? (
        <DialogContent className={styles.bookingConfirmation}>
          <DialogSuccess handleDialogClose={handleDialogClose} />
        </DialogContent>
      ) : (
        <DialogContent className={styles.bookingConfirmation}>
          <div className={styles.dialogConfirmation}>
            <DialogHeader handleDialogClose={handleDialogClose} />
            <DialogBody
              booking={booking}
              firstName={firstName}
              appointmentType={appointmentType}
              location={location}
            />
            <DialogFooter handleConfirm={handleConfirm} />
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
