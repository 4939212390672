import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BookingsSocket from './BookingsSocket'
import subscribeToChannel from '../../services/socket'

const Socket = ({debug, channelName}) => {
  const [booking, setBooking] = useState(null)

  // socket connections on instance

  const createSocketCbs = () => ({
    connected() {
      console.log('connected', channelName)
    },

    disconnected() {
      console.log('disconnected', channelName)
    },

    rejected() {
      console.log('rejected', channelName)
    },

    received(booking) {
      console.log('received', channelName)
      setBooking(booking)
    },
  })
  
  useEffect(() => {
    const _consumer = subscribeToChannel(channelName, createSocketCbs())

    if (debug) {
      window[`_consumer_${channelName}`] = _consumer
    }

    return function cleanup() {
      _consumer.disconnected()
    }
  })
  return (
    <div className="Socket">
      <BookingsSocket booking={!!booking ? booking.booking : null} />
    </div>
  )
}

Socket.propTypes = {
  debug: PropTypes.bool,
  channelName: PropTypes.string.isRequired,
}

export default Socket
