import React from 'react'
import moment from 'moment-timezone'
import Button from '@material-ui/core/Button'
import styles from '../schedule.module.css'

export default function BookingsColumn(props) {
  const { data, handleSelectBooking, selectedBooking } = props

  return (
    <div className={styles.column}>
      {
        data.map((booking) => {
          if (booking === '--') {
            return (
              <div className={styles.booking} key={Math.random()}>
                <p className={styles.noBooking}>{booking}</p>
              </div>
            )
          } else if (typeof booking === 'string' || booking instanceof String) {
            return(
              <div className={styles.date} key={booking}>
                <p>{booking.split(' ')[0]}</p>
                <p>{booking.split(' ')[1]}</p>
              </div>
            )
          } else {
            return(
              <Button
                className={styles.booking}
                color="primary"
                disabled={selectedBooking && booking.id === selectedBooking.id}
                key={booking.id}
                onClick={() => handleSelectBooking(booking)}
                variant="contained"
              >
                {moment(booking.start_datetime).format('h:mm a')}
              </Button>
            )
          }
        })
      }

    </div>
  )
}
