import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import styles from './patientInfo.module.css'

const UpdateInfoBox = ({ errors, inputRef, label, name, onChange, value }) => {
  return <div className={styles.infoBox} key={name}>
    <h5>{label}</h5>
    <TextField
      error={errors}
      className={styles.updateField}
      name={name}
      onChange={onChange}
      inputRef={inputRef}
      value={value}
    />
  </div>
}

UpdateInfoBox.propTypes = {
  errors: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default UpdateInfoBox
