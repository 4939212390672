import React from 'react'
import styles from './portal.module.css'
import ApptList from './ApptList'
import { useGlobalState } from '../../state'

export default function AppointmentContainer(props) {
  const { isNextAppts, subTitle, title } = props

  const [{ practice }] = useGlobalState()

  const apptVisitType = isNextAppts ? 'next_visits' : 'appointments_for_pp'
  const apptDate = isNextAppts ? 'date_specified' : 'appointment_datetime'

  return (
    <div className={styles.appointmentContainer}>
      <h4>{title}</h4>
      { subTitle && <p>{subTitle}</p> }
      <hr className={styles.apptContDivide} />
      <ApptList
        apptDate={apptDate}
        apptVisitType={apptVisitType}
        isNextAppts={isNextAppts}
        practice={practice}
      />
    </div>
  )
}
