import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import styles from './services.module.css'

export default function Body(props) {
  const {
    showPassword,
    handleClickShowPassword,
    handleMouseDownShowPassword,
  } = props

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={(event) => handleMouseDownShowPassword(event)}
        edge="end"
      >
        <span className={styles.passVisText}>{showPassword ? 'hide' : 'show'}</span>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )
}
