import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './patientInfo.module.css'
import { updatePatientInformation } from '../../services/fetchHelpers'
import { filterPatientNextVisits } from '../../services/utils'
import {
  formatPhoneNumberInput,
  formatPhoneNumberDomestic,
} from '../../services/phoneNumberFormatters'
import UpdateCorrespondence from './UpdateCorrespondence'
import UpdateContacts from './UpdateContacts'

const UpdateInfoColumns = ({
  activePatient,
  activePatient: {
    id,
    preferred_name,
    reminders_email: remindersEmail,
    reminders_phone: remindersPhone,
    second_reminders_email: secondRemEmail,
    second_reminders_phone: secondRemPhone,
  },
  acctEmail,
  cloud9UseCorrespondenceValues,
  dispatch,
  locations: [{ country_code: country } = { country_code: 'US' }],
  nextVisitToSchedule,
  products,
}) => {
  const [loading, setLoading] = useState(false)
  const [preferredEmail, setPreferredEmail] = useState(remindersEmail || '')
  const [preferredName, setPreferredName] = useState(preferred_name || '')
  const [preferredPhone, setPreferredPhone] = useState(
    formatPhoneNumberDomestic(remindersPhone) || '',
  )
  const [phoneErrors, setPhoneErrors] = useState(false)
  const [secondPhoneErrors, setSecondPhoneErrors] = useState(false)
  const [secondEmail, setSecondEmail] = useState(secondRemEmail || '')
  const [secondPhone, setSecondPhone] = useState(
    formatPhoneNumberDomestic(secondRemPhone) || '',
  )
  const phoneRef = useRef(null)
  const secondPhoneRef = useRef(null)

  useEffect(() => {
    setPreferredEmail(remindersEmail)
    setPreferredName(preferred_name)
    setPreferredPhone(formatPhoneNumberDomestic(remindersPhone))
    setSecondEmail(secondRemEmail)
    setSecondPhone(formatPhoneNumberDomestic(secondRemPhone))
  }, [
    preferred_name,
    remindersEmail,
    remindersPhone,
    secondRemEmail,
    secondRemPhone,
  ])

  const onChange = ({ target: { name, value } }) =>
    setStateMethodMap[name](value)

  const onPhoneNumberChange = (
    { target: { name, selectionStart, value } },
    prevValue,
    ref,
  ) => {
    if (value === '') {
      return setStateMethodMap[name](value)
    }
    const [newPhoneNumber, validPhoneNumber] = formatPhoneNumberInput(
      value,
      prevValue.length,
      country,
    )
    if (!validPhoneNumber) {
      setStateMethodMap[`${name}Errors`](true)
    } else {
      setStateMethodMap[`${name}Errors`](false)
    }
    setStateMethodMap[name](newPhoneNumber)
    // Correct input position based on how formatting has changed input length
    const lengthDiff = newPhoneNumber.length - value.length
    const caretPos = Math.max(selectionStart + lengthDiff, 0)
    ref.current.setSelectionRange(caretPos, caretPos)
  }

  const noFieldChanges =
    (remindersEmail !== null
      ? remindersEmail === preferredEmail
      : preferredEmail === '') &&
    (preferred_name !== null
      ? preferred_name === preferredName
      : preferredName === '') &&
    (formatPhoneNumberDomestic(remindersPhone) !== null
      ? formatPhoneNumberDomestic(remindersPhone) === preferredPhone
      : preferredPhone === '') &&
    (secondRemEmail !== null
      ? secondRemEmail === secondEmail
      : secondPhone === '') &&
    (formatPhoneNumberDomestic(secondRemPhone) !== null
      ? formatPhoneNumberDomestic(secondRemPhone) === secondPhone
      : secondEmail === '')

  const isDisabled = noFieldChanges || phoneErrors || secondPhoneErrors

  const onSubmit = async () => {
    try {
      if (loading) return false
      setLoading(true)
      const { patients } = await updatePatientInformation(id, {
        email: acctEmail,
        preferred_email: preferredEmail,
        preferred_name: preferredName,
        preferred_phone: preferredPhone,
        second_reminders_email: secondEmail,
        second_reminders_phone: secondPhone,
      })
      dispatchPatients(patients)
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  const dispatchPatients = (patients) => {
    const patientToUpdate = patients.find(pat => pat.id === id)
    dispatch({
      type: 'setPatients',
      value: filterPatientNextVisits(patients),
    })
    dispatch({
      type: 'setActivePatient',
      value: {
        patient: patientToUpdate,
        nextVisit: nextVisitToSchedule,
      },
    })
  }

  const setStateMethodMap = {
    email: setPreferredEmail,
    name: setPreferredName,
    phone: setPreferredPhone,
    secondEmail: setSecondEmail,
    secondPhone: setSecondPhone,
    phoneErrors: setPhoneErrors,
    secondPhoneErrors: setSecondPhoneErrors,
  }

  const baseFields = [
    {
      errors: null,
      label: 'Preferred Email',
      name: 'email',
      onChange,
      inputRef: null,
      value: preferredEmail,
    },
    {
      errors: null,
      label: 'Preferred Name',
      name: 'name',
      onChange,
      inputRef: null,
      value: preferredName,
    },
    {
      errors: phoneErrors,
      label: 'Preferred Phone',
      name: 'phone',
      onChange: e => onPhoneNumberChange(e, preferredPhone, phoneRef),
      inputRef: phoneRef,
      value: preferredPhone,
    },
  ]

  const remindersTier2Fields = [
    {
      errors: null,
      label: 'Second Email',
      name: 'secondEmail',
      onChange,
      inputRef: null,
      value: secondEmail,
    },
    {
      errors: secondPhoneErrors,
      label: 'Second Phone',
      name: 'secondPhone',
      onChange: e => onPhoneNumberChange(e, secondPhone, secondPhoneRef),
      inputRef: secondPhoneRef,
      value: secondPhone,
    },
  ]

  const fieldsToUpdate =
    products.includes('reminderstier2') ||
    products.includes('topsremindersplus')
      ? [...baseFields, ...remindersTier2Fields]
      : baseFields

  return (
    <div className={styles.updateColumn}>
      <div className={styles.infoColumnTitle}>
        <h3>Contact Information</h3>
      </div>
      <div className={styles.updateInfoColumn}>
        <UpdateContacts
          fieldsToUpdate={fieldsToUpdate}
          isDisabled={isDisabled}
          onSubmit={onSubmit}
        />
      </div>
      <div className={styles.updateInfoColumn}>
        <UpdateCorrespondence
          activePatient={activePatient}
          acctEmail={acctEmail}
          cloud9UseCorrespondenceValues={cloud9UseCorrespondenceValues}
          dispatchPatients={dispatchPatients}
          nextVisitToSchedule={nextVisitToSchedule}
        />
      </div>
    </div>
  )
}

UpdateInfoColumns.propTypes = {
  activePatient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    preferred_name: PropTypes.string,
    reminders_email: PropTypes.string,
    reminders_phone: PropTypes.string,
    second_reminders_email: PropTypes.string,
    second_reminders_phone: PropTypes.string,
  }),
  acctEmail: PropTypes.string.isRequired,
  cloud9UseCorrespondenceValues: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
}

export default UpdateInfoColumns
