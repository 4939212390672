import React from 'react'
import AppointmentCard from './ApptCard'
import styles from './portal.module.css'
import {
  apptTimeDate,
  multiPatientApptFilter,
  singlePatientApptFilter,
} from './utils'
import { useGlobalState } from '../../state'

export default function ApptList({
  apptDate,
  apptVisitType,
  isNextAppts,
  practice,
}) {
  const [{ patients, activePatient }] = useGlobalState()

  const apptData = activePatient
    ? singlePatientApptFilter(activePatient, apptVisitType, apptDate)
    : multiPatientApptFilter(patients, apptVisitType, apptDate)

  const patientsNoAppts = `We have no ${
    apptVisitType === 'next_visits' ? 'recommended ' : 'upcoming'
  } appointments for ${apptData.patientsNoAppts.join(', ')}`

  const apptCards = apptData.apptArr.map(appointment => {
    const currPatient = patients.find(
      patient => patient.id === appointment.patient_id,
    )
    return (
      <div key={appointment.id}>
        <AppointmentCard
          appointment={appointment}
          isNextAppts={isNextAppts}
          apptTime={apptTimeDate(appointment, apptDate)}
          practice={practice}
          patient={currPatient}
        />
      </div>
    )
  })

  return (
    <div>
      {!!apptData.apptArr.length && <div>{apptCards}</div>}
      {!!apptData.patientsNoAppts.length && (
        <h5 className={styles.noPatients}>{patientsNoAppts}</h5>
      )}
    </div>
  )
}
