import React from 'react'
import moment from 'moment-timezone'
import styles from '../schedule.module.css'

export default function DialogBody(props) {
  const {
    booking,
    firstName,
    appointmentType,
    location: { name, office_phone: phone },
  } = props

  const formattedDatetime = moment(booking.start_datetime).format(
    'MMMM D, YYYY | h:mm a'
  )

  return (
    <div>
      <div className={styles.dialogRow}>
        <div className={styles.dialogColLeft}>
          <p>When:</p>
        </div>
        <div className={styles.dialogColRight}>
          <p>{formattedDatetime}</p>
        </div>
      </div>
      <div className={styles.dialogRow}>
        <div className={styles.dialogColLeft}>
          <p>Where:</p>
        </div>
        <div className={styles.dialogColRight}>
          <p>{name}</p>
          <p>{phone}</p>
        </div>
      </div>
      <div className={styles.dialogRow}>
        <div className={styles.dialogColLeft}>
          <p>Patient and Reason:</p>
        </div>
        <div className={styles.dialogColRight}>
          <p>{`${firstName} for ${appointmentType}`}</p>
        </div>
      </div>
    </div>
  )
}
