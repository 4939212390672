import React from 'react'
import Button from '@material-ui/core/Button'
import EventIcon from '@material-ui/icons/Event'
import SelectedBooking from './SelectedBooking'
import BookingConfirmation from '../BookingConfirmation'
import styles from '../schedule.module.css'

export default function BookingsFooter({
  apptToReschedule,
  confirming,
  dialogOpen,
  handleConfirm,
  handleDialogClose,
  handleRemoveBooking,
  handleReviewAndConfirm,
  location,
  nextVisit: { appointment_type_description: appointmentType },
  patient: { first_name: firstName, preferred_name: preferredName },
  selectedBooking,
  success,
}) {
  return (
    <div className={styles.bookingsFooter}>
      <div className={styles.selectedBookingBox}>
        <div className={styles.calendarIconBox}>
          <EventIcon className={styles.calendarIcon} />
          <h5 className={styles.detailsBoxTitle}>Selected Appointments:</h5>
        </div>
        {!!selectedBooking && (
          <SelectedBooking
            selectedBooking={selectedBooking}
            handleRemoveBooking={handleRemoveBooking}
            firstName={preferredName ? preferredName : firstName}
            appointmentType={appointmentType}
          />
        )}
      </div>
      <div className={styles.confirm}>
        <Button
          className={styles.button}
          color="primary"
          onClick={handleReviewAndConfirm}
          disabled={!selectedBooking}
          variant="contained"
        >
          {!!apptToReschedule ? `Review and Reschedule` : `Review and Confirm`}
        </Button>
      </div>
      {!!selectedBooking && (
        <BookingConfirmation
          confirming={confirming}
          dialogOpen={dialogOpen}
          handleDialogClose={handleDialogClose}
          booking={selectedBooking}
          handleConfirm={handleConfirm}
          success={success}
          firstName={preferredName ? preferredName : firstName}
          appointmentType={appointmentType}
          location={location}
        />
      )}
    </div>
  )
}
