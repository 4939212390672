import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { Auth } from 'aws-amplify'
import AuthFlow from './components/auth'
import Portal from './components/portal'
import Settings from './components/settings'
import Navigation from './components/navigation'
import PatientInfo from './components/patientInfo'
import Schedule from './components/schedule'
import { useGlobalState } from './state'
import { getPatients, getPractice } from './services/fetchHelpers'
import { filterPatientNextVisits } from './services/utils'
import Spinner from './services/Spinner'

export default function AppRouter() {
  const [
    {
      activePatient,
      authenticated,
      authenticating,
      practice,
      patients,
      subdirectory,
      user,
    },
    dispatch,
  ] = useGlobalState()

  useEffect(() => {
    ;(async () => {
      if (!practice) {
        let currUser = null
        try {
          currUser = await Auth.currentAuthenticatedUser()
          if (currUser) {
            const practiceData = await getPractice()
            const patientData = await getPatients(currUser.attributes.email)
            dispatch({
              type: 'setInitialData',
              value: {
                authenticated: true,
                authenticating: false,
                user: currUser,
                practice: practiceData.practice,
                patients: filterPatientNextVisits(patientData.patients),
              },
            })
          }
        } catch (error) {
          console.error(error)
          dispatch({
            type: 'setAuthenticating',
            value: false,
          })
        }
      }
    })()
  }, [dispatch, practice, patients, user])

  const patientInfoRedirect = () => {
    if (!authenticated) {
      return <Redirect to={`${subdirectory}login`} />
    } else if (!activePatient) {
      return <Redirect to={`${subdirectory}`} />
    }
  }

  return authenticating ? (
    <Spinner />
  ) : (
    <div>
      <Router>
        <div>
          {authenticated && <Navigation />}
          <Switch>
            <Route
              exact
              path={`${subdirectory}signup`}
              render={props => <AuthFlow {...props} authType='signup' />}
            >
              {authenticated && <Redirect to={`${subdirectory}`} />}
            </Route>
            <Route
              exact
              path={`${subdirectory}login`}
              render={props => <AuthFlow {...props} authType='login' />}
            >
              {authenticated && <Redirect to={`${subdirectory}`} />}
            </Route>
            <Route
              exact
              path={`${subdirectory}forgotpassword`}
              render={props => (
                <AuthFlow {...props} authType='forgotPassword' />
              )}
            >
              {authenticated && <Redirect to={`${subdirectory}`} />}
            </Route>
            <Route exact path={`${subdirectory}`}>
              {!authenticated ? (
                <Redirect to={`${subdirectory}login`} />
              ) : (
                <Portal />
              )}
            </Route>
            <Route exact path={`${subdirectory}settings`}>
              {!authenticated ? (
                <Redirect to={`${subdirectory}login`} />
              ) : (
                <Settings />
              )}
            </Route>
            <Route
              exact
              path={`${subdirectory}schedule`}
              render={props => <Schedule {...props} />}
            >
              {!authenticated && <Redirect to={`${subdirectory}login`} />}
            </Route>
            <Route
              exact
              path={`${subdirectory}info`}
              render={props => <PatientInfo {...props} />}
            >
              {patientInfoRedirect()}
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}
