import React from 'react'
import { useGlobalState } from '../../../state'
import styles from '../schedule.module.css'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogContentText,
} from '@material-ui/core'
import { formatPhoneNumberDotSpacing } from '../../../services/phoneNumberFormatters'

export default function NoBookingsPopOver(props) {
  const { initializing } = props
  const [
    {
      bookings,
      nextVisitToSchedule: { location },
    },
    dispatch,
  ] = useGlobalState()

  const returnToScheduleSelect = () => {
    dispatch({
      type: 'setCalendarView',
      value: false,
    })
  }

  const { office_phone } = location

  const phoneNumber = formatPhoneNumberDotSpacing(office_phone)
  
  return (
    <Dialog open={!Object.keys(bookings).length && !initializing}>
      <DialogTitle id="scroll-dialog-title">We're sorry...</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <span>
            {`This appointment is currently unavailable for online scheduling.
Please try calling the office ${phoneNumber ? `at: ` : ``}`}
          </span>
          {phoneNumber && (
            <a
              className={styles.noBookingsLink}
              href={`tel:${office_phone}`}
            >
              {phoneNumber}
            </a>
          )}
        </DialogContentText>
        <Button
          color="primary"
          fullWidth
          disableElevation
          onClick={returnToScheduleSelect}
          variant="contained"
        >
          Back to Selection
        </Button>
      </DialogContent>
    </Dialog>
  )
}
