// account
export const MFA = 'setMFA'
export const SET_LEGAL_AGREED = 'toggleLegalAgreed'
export const PATIENTS = 'setPatients'
export const PRACTICE = 'setPractice'

// auth
export const AUTHENTICATED = 'setAuthenticated'
export const AUTHENTICATING = 'setAuthenticating'
export const LOADING = 'setLoading'
export const EMAIL = 'setEmail'
export const CODE = 'setCode'
export const PASSWORD = 'setPassword'
export const NEW_PASSWORD = 'setNewPassword'
export const PASS_VALIDITY = 'setPassValidity'
export const LOGIN = 'login'
export const INITIAL_DATA = 'setInitialData'
export const CLEAR_AUTH_STATE = 'clearAuthState'
export const SIGN_OUT = 'signOut'

// schedule
export const ACTIVE_PATIENT = 'setActivePatient'
export const NEXT_VISIT_TO_SCHEDULE = 'setNextVisitToSchedule'
export const APPT_TO_RESCHEDULE = 'setApptToReschedule'
export const DATE = 'setDate'
export const CALENDAR_VIEW = 'setCalendarView'
export const BOOKINGS = 'setBookings'
export const INITIALIZE_BOOKINGS = 'initializeBookings'
export const SELECTED_BOOKING = 'setSelectedBooking'
export const CLEAR_SELECTED_BOOKING = 'clearSelectedBooking'
export const CLEAR_APPT_TO_RESCHED = 'clearApptToResched'
export const CLEAR_BOOKINGS = 'clearBookings'
export const SUCESS_REFETCH = 'successRefetch'
