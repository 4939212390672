import ActionCable from 'actioncable'
import { tokenStorage } from './localStorage'

const setConsumerUrl = (apiBaseUrl) => {
  const matchData = apiBaseUrl.match(/https?/)
  const socketProtocol = /localhost/.test(apiBaseUrl) ? 'ws' : 'wss'
  const token = tokenStorage.get()

  const socketUrl = matchData ?
    apiBaseUrl.replace(matchData[0], socketProtocol) :
    socketProtocol + apiBaseUrl

  return `${socketUrl.replace('/api/v1/patient_portal','')}/cable?token=${token}&patient_portal=true`
}

const createConsumer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
  const socketUrl = setConsumerUrl(apiBaseUrl)
  return ActionCable.createConsumer(socketUrl)
}

export default (channelName, componentInterface) => {
  const consumer = createConsumer()

  return consumer
    .subscriptions
    .create(channelName, componentInterface)
}
