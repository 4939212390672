import React from 'react'
import { Button } from '@material-ui/core'
import Header from './Header'
import AuthInputField from './AuthInputField'
import styles from './auth.module.css'
import { useGlobalState } from '../../state'

export default function LogIn(props) {
  const [{ email, password, subdirectory }] = useGlobalState()
  const { handleLogin, history, authErrors } = props

  const textObject = {
    text: 'forgot password?',
    onClick: () => history.push(`${subdirectory}forgotpassword`),
  }

  const signUpObject = {
    text: 'need an account? sign up',
    onClick: () => history.push(`${subdirectory}signup`),
  }

  return (
    <div className={styles.authPage}>
      <Header text="hello." />
      <div className={styles.loginFieldsContainer}>
        <AuthInputField
          type="email"
          authErrors={authErrors}
          value={email}
          handleSubmit={handleLogin}
        />
        <AuthInputField
          type="password"
          authErrors={authErrors}
          login
          value={password}
          textObject={textObject}
          handleSubmit={handleLogin}
        />
      </div>
      <Button
        className={styles.appButton}
        color="primary"
        disabled={!(email && password)}
        disableElevation
        fullWidth
        onClick={handleLogin}
        variant="contained"
      >
        sign in
      </Button>
      <span className={styles.textObject} onClick={signUpObject.onClick}>
        {signUpObject.text}
      </span>
    </div>
  )
}
