import React from 'react'
import NavigationTop from './NavigationTop'
import SideBar from './SideBar'

export default function Navigation() {

  return (
    <div>
      <NavigationTop />
      <SideBar />
    </div>
  )
}
