import { useGlobalState } from '../state'

export const isLongEnough = (password) => {
  return password.length >= 8
}

export const hasUpperAndLower = (password) => {
  return !!password.match(/[A-Z]/) && !!password.match(/[a-z]/)
}

export const hasNumbers = (password) => {
  return password.match(/[0-9]/)
}

export const hasSpecial = (password) => {
  return password.match(/[!@#$%^&*(),.?":{}|<>]/)
}

export const reducerTypeMapping = {
  email: 'setEmail',
  code: 'setCode',
  password: 'setPassword',
  newPassword: 'setNewPassword',
}

export const emailValidator = (email) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)

const emailNoSpaces = (email) => email.replace(/\s+/g, '')

export function useAuthHandleChange() {
  const [, dispatch] = useGlobalState()

  const setGlobalState = (type, value) => {
    dispatch({
      type: type,
      value: value,
    })
  }

  return (event, actionType, validate = false) => {
    let input = event.target.value
    if (actionType === 'email') {
      const cleanedEmail = emailNoSpaces(input)
      input = cleanedEmail
    }
    if (validate) {
      const validity = {
        isLongEnough: isLongEnough(input) ? true : false,
        hasUpperAndLower: hasUpperAndLower(input) ? true : false,
        hasNumbers: hasNumbers(input) ? true : false,
        hasSpecial: hasSpecial(input) ? true : false,
      }
      setGlobalState('setPassValidity', validity)
    }
    setGlobalState(reducerTypeMapping[actionType], input)
  }
}
