import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const UpdateInfoCheckBox = ({ label, name, value, onChange }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={value}
        name={name}
        onChange={onChange}
        color='primary'
      />
    }
    label={label}
  />
)

UpdateInfoCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}

export default UpdateInfoCheckBox
