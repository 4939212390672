import React, { useState } from 'react'
import PropTypes from 'prop-types'
import UpdateInfoCheckBox from './UpdateInfoCheckBox'
import { updatePatientInformation } from '../../services/fetchHelpers'

const UpdateCorrespondence = ({
  activePatient: {
    email,
    id,
    opt_in_email_apt_remind: optInEmailApptRemind,
    opt_in_text_apt_remind: optInTextApptRemind,
  },
  cloud9UseCorrespondenceValues,
  dispatchPatients,
}) => {
  const [loading, setLoading] = useState(false)
  const correspondenceValuesToUpdate = [
    {
      name: 'opt_in_email_apt_remind',
      label: 'Recieve Reminder Emails',
      value: optInEmailApptRemind,
    },
    {
      name: 'opt_in_text_apt_remind',
      label: 'Recieve Reminder Texts',
      value: optInTextApptRemind,
    },
  ]

  const onChckBxChange = async ({ target: { checked, name } }) => {
    try {
      if (loading) return false
      setLoading(true)
      const { patients } = await updatePatientInformation(id, {
        email,
        [name]: checked,
      })
      dispatchPatients(patients)
      setLoading(false)
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  return (
    <>
      {!cloud9UseCorrespondenceValues && (
        <>
          <h5>How may we contact you?</h5>
          {correspondenceValuesToUpdate.map(({ name, label, value }) => (
            <UpdateInfoCheckBox
              key={name}
              name={name}
              label={label}
              value={value}
              onChange={onChckBxChange}
            />
          ))}
        </>
      )}
    </>
  )
}

UpdateCorrespondence.propTypes = {
  activePatient: PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    opt_in_email_apt_remind: PropTypes.bool.isRequired,
    opt_in_text_apt_remind: PropTypes.bool.isRequired,
  }),
  acctEmail: PropTypes.string.isRequired,
  cloud9UseCorrespondenceValues: PropTypes.bool.isRequired,
  dispatchPatients: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.string),
}

export default UpdateCorrespondence
