import React, { useState, useEffect, useCallback } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import cx from 'classnames'
import styles from './navigation.module.css'
import NavigationLink from './NavigationLink'
import { useGlobalState } from '../../state'
import { setBookingOpen } from '../../services/fetchHelpers'
import { updateNextVisitToSched } from '../../services/utils'

export default function SideBarDropDown() {
  const [
    {
      activePatient,
      patients,
      selectedBooking,
      practice: { locations },
      subdirectory,
    },
    dispatch,
  ] = useGlobalState()
  const [dropDownShowing, setDropDownShowing] = useState(false)

  const patientAndAppointment = useCallback(
    patientToUpdate => ({
      patient: patientToUpdate,
      nextVisit: updateNextVisitToSched(patientToUpdate, locations),
    }),
    [locations],
  )

  useEffect(() => {
    if (patients.length === 1) {
      dispatch({
        type: 'setActivePatient',
        value: patientAndAppointment(patients[0]),
      })
    }
  }, [patientAndAppointment, patients, dispatch])

  const toggleDropDown = () => {
    setDropDownShowing(!dropDownShowing)
  }

  const dropDownStyles = cx(styles.dropDown, {
    [`${styles.dropDownShowing}`]: dropDownShowing,
  })

  const dropDownOverlayStyles = cx(styles.dropDownOverlay, {
    [`${styles.dropDownOverlayActive}`]: dropDownShowing,
  })

  const setCurrentPatient = async patientToUpdate => {
    if (selectedBooking) await setBookingOpen(selectedBooking.id)

    dispatch({
      type: 'setApptToReschedule',
      value: {
        nextVisitToSchedule: {
          patient: { id: '', label: '' },
          nextVisit: { id: '', label: '' },
          location: { id: '', label: '' },
        },
        apptToReschedule: null,
      },
    })

    dispatch({
      type: 'setActivePatient',
      value: patientAndAppointment(patientToUpdate),
    })

    toggleDropDown()
  }

  const patientList = patients.map(patient => {
    const {
      first_name: firstName,
      id,
      last_name: lastName,
      preferred_name: preferredName,
    } = patient
    return (
      <NavigationLink
        content={`${preferredName ? preferredName : firstName} ${lastName}`}
        key={id}
        navClassName={styles.dropDownItem}
        onClick={() => setCurrentPatient(patient)}
        to={`${subdirectory}`}
      />
    )
  })

  const dropDownHeader =
    patients.length === 1 ? (
      <div
        className={`${styles.sideDropControl} ${styles.sideDropControlDisabled}`}
      >
        <h6>{`${
          patients[0].preferred_name
            ? patients[0].preferred_name
            : patients[0].first_name
        } ${patients[0].last_name}`}</h6>
      </div>
    ) : (
      <div className={styles.sideDropControl} onClick={toggleDropDown}>
        <h6>
          {activePatient
            ? `${
                activePatient.preferred_name
                  ? activePatient.preferred_name
                  : activePatient.first_name
              } ${activePatient.last_name}`
            : `Overview (All)`}
        </h6>
        <ArrowDropDownIcon />
      </div>
    )

  return (
    <div className={styles.sideHeader}>
      {dropDownHeader}
      <div
        className={dropDownOverlayStyles}
        onClick={() => setDropDownShowing(false)}
      />
      <div className={dropDownStyles}>
        <NavigationLink
          content={`Overview (All)`}
          key={'overview'}
          navClassName={styles.dropDownItem}
          onClick={() => setCurrentPatient(null)}
          to={`${subdirectory}`}
        />
        {patientList}
      </div>
    </div>
  )
}
