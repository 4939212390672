import React from 'react'
import Button from '@material-ui/core/Button'
import styles from './schedule.module.css'
import { useGlobalState } from '../../state'
import { formatPhoneNumberDotSpacing } from '../../services/phoneNumberFormatters'

export default function ScheduleContainer({ selectComps, isDisabled }) {
  const [
    {
      practice: { locations },
    },
    dispatch,
  ] = useGlobalState()

  const { office_phone } = locations[0]

  const handleSubmit = () => {
    dispatch({
      type: 'setCalendarView',
      value: true,
    })
  }

  const niceNumber = formatPhoneNumberDotSpacing(office_phone)

  return (
    <div className={styles.scheduleContainer}>
      <div className={styles.selectorContainer}>{selectComps}</div>
      <Button
        disabled={isDisabled}
        onClick={handleSubmit}
        color="primary"
        variant="contained"
      >
        Find Appointments
      </Button>
      <div className={styles.scheduleSubtext}>
        <span>
          {`For emergencies, please call the office immediately${
            office_phone ? ` at: ` : ``
          } `}
        </span>
        {office_phone && <a href={`tel:${office_phone}`}>{niceNumber}</a>}
      </div>
    </div>
  )
}
