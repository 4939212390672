import React from 'react'
import styles from './auth.module.css'

export default function Header(props) {
  const { text } = props

  return (
    <div className={styles.centerTitle}>
      <h1 className="JumboText">{text}</h1>
    </div>
  )
}
