import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { IconButton, Tooltip } from '@material-ui/core'
import { useGlobalState } from '../../state'
import RescheduleGuardDialog from './RescheduleGuardDialog'

const NavigationLink = ({
  active,
  content,
  navClassName,
  isIconButton,
  onClick,
  styles,
  to,
  toolTipTitle,
}) => {
  const [{ apptToReschedule }, dispatch] = useGlobalState()
  const [showConfirm, setShowConfirm] = useState(false)

  const handleClose = () => setShowConfirm(false)

  const handleClick = e => {
    if (!!apptToReschedule) {
      e.preventDefault()
      setShowConfirm(true)
    } else if (!!onClick) {
      onClick()
    }
  }

  const handleConfirm = () => {
    dispatch({
      type: 'setApptToReschedule',
      value: {
        nextVisitToSchedule: {
          patient: { id: '', label: '' },
          nextVisit: { id: '', label: '' },
          location: { id: '', label: '' },
        },
        apptToReschedule: null,
      },
    })
    setShowConfirm(false)
    if (!!onClick) {
      onClick()
    }
  }

  return (
    <>
      {isIconButton ? (
        <Tooltip title={toolTipTitle}>
          <IconButton
            className={navClassName}
            component={NavLink}
            disableFocusRipple
            disableRipple
            exact
            onClick={handleClick}
            to={to}
          >
            {content}
          </IconButton>
        </Tooltip>
      ) : (
        <NavLink
          activeClassName={active ? styles.active : ''}
          className={navClassName}
          exact
          onClick={handleClick}
          to={to}
        >
          {content}
        </NavLink>
      )}
      <RescheduleGuardDialog
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        showConfirm={showConfirm}
        message='Note: If you leave this page you must restart the rescheduling process.'
        to={to}
      />
    </>
  )
}

NavigationLink.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.any,
  isIconButton: PropTypes.bool,
  navClassName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.objectOf(PropTypes.string.isRequired),
  to: PropTypes.string.isRequired,
  toolTipTitle: PropTypes.string,
}

export default NavigationLink
