import React from 'react'
import Button from '@material-ui/core/Button'
import Header from './Header'
import AuthInputField from './AuthInputField'
import Footer from './Footer'
import { useGlobalState } from '../../state'
import styles from './auth.module.css'

export default function ForgotPassword(props) {
  const {
    authErrors,
    handleClearErrors,
    handleForgotPassword,
    handleForgotPasswordVerification,
    history,
    navigateNextPage,
    navigatePrevPage,
    pageNum,
    setPageNum,
  } = props
  const [{ email, code, password, subdirectory }, dispatch] = useGlobalState()

  const handleEmailSubmit = async () => {
    await handleForgotPassword()
    navigateNextPage()
  }

  const handlePasswordSubmit = async () => {
    const res = await handleForgotPasswordVerification()
    if (res) {
      navigateNextPage()
    } else {
      return false
    }
  }

  const pages = [
    {
      type: 'email',
      input: (
        <AuthInputField
          type="email"
          authErrors={authErrors}
          value={email}
          handleSubmit={handleEmailSubmit}
        />
      ),
      handleNext: handleEmailSubmit,
      handlePrev: null,
      headerText: 'oops!',
      buttonText: 'next',
    },
    {
      type: 'code',
      input: (
        <AuthInputField
          type="code"
          authErrors={authErrors}
          handleResendEmail={handleForgotPassword}
          value={code}
          handleSubmit={navigateNextPage}
        />
      ),
      handleNext: navigateNextPage,
      handlePrev: navigatePrevPage,
      headerText: 'check your email!',
      buttonText: 'next',
    },
    {
      type: 'password',
      input: (
        <AuthInputField
          type="password"
          authErrors={authErrors}
          value={password}
          validate={true}
          handleSubmit={handlePasswordSubmit}
        />
      ),
      handleNext: handlePasswordSubmit,
      handlePrev: navigatePrevPage,
      headerText: 'new password',
      buttonText: 'change password',
    },
    {
      type: 'null',
      input: null,
      handleNext: null,
      handlePrev: null,
      headerText: authErrors
        ? 'password reset unsuccessful, please try again later'
        : 'password successfully reset!',
      buttonText: null,
    },
  ]

  const textObject = {
    text: 'return to log in',
    onClick: () => {
      dispatch({ type: 'clearAuthState' })
      handleClearErrors()
      history.push(`${subdirectory}login`)
    },
  }

  return (
    <div className={styles.authPage}>
      <Header text={pages[pageNum].headerText} />
      {pages[pageNum].input}
      <Footer
        buttonText={pages[pageNum].buttonText}
        handleNext={pages[pageNum].handleNext}
        handlePrev={pages[pageNum].handlePrev}
        numPages={pages.length}
        pageNum={pageNum}
        setPageNum={setPageNum}
        textObject={textObject}
        type={pages[pageNum].type}
      />
      {pageNum === 3 && (
        <div className={styles.footer}>
          <Button
            className={styles.appButton}
            color="primary"
            disableElevation
            fullWidth
            onClick={() => textObject.onClick()}
            variant="contained"
          >
            {textObject.text}
          </Button>
        </div>
      )}
    </div>
  )
}
