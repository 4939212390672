import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Card, CardHeader, Collapse } from '@material-ui/core'
import styles from './portal.module.css'
import AppointmentCardContent from './ApptCardContent'
import ToolTipButton from './ToolTipButton'
import {
  disableButton,
  shapeNextVisitToSched,
  findNextVisitAppt,
} from './utils'
import { useGlobalState } from '../../state'
import { setBookingOpen } from '../../services/fetchHelpers'
import RescheduleMoreInfoButton from './RescheduleMoreInfoButton'
import RescheduleGuardDialog from '../navigation/RescheduleGuardDialog'

export default function AppointmentCard({
  appointment,
  apptTime,
  patient,
  patient: {
    first_name: firstName,
    last_name: lastName,
    next_visits: nextVisits,
    preferred_name: preferredName,
  },
  isNextAppts,
  practice,
}) {
  const [expanded, setExpanded] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [{ selectedBooking, subdirectory }, dispatch] = useGlobalState()

  const patientName = `${preferredName ? preferredName : firstName} ${lastName}`

  const apptType = appointment.appointment_type_description
    ? `for ${appointment.appointment_type_description}`
    : ''

  const selectAppointment = async () => {
    if (selectedBooking) await setBookingOpen(selectedBooking.id)
    dispatch({
      type: 'setApptToReschedule',
      value: {
        nextVisitToSchedule: shapeNextVisitToSched(
          patient,
          appointment,
          practice.locations,
        ),
        apptToReschedule: null,
      },
    })
  }

  const handleClose = () => {
    setShowConfirm(false)
  }

  const promptShouldReschedAppt = () => {
    setShowConfirm(true)
  }

  const reschedAppt = async () => {
    if (selectedBooking) await setBookingOpen(selectedBooking.id)
    const nextVisitToReschedule = findNextVisitAppt(appointment, patient)
    const nextVisitReschedObject = {
      nextVisitToSchedule: shapeNextVisitToSched(
        patient,
        nextVisitToReschedule,
        practice.locations,
      ),
      apptToReschedule: appointment,
    }
    dispatch({
      type: 'setApptToReschedule',
      value: nextVisitReschedObject,
    })
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const scheduleNowButton = () =>
    disableButton(appointment) ? (
      <ToolTipButton subdirectory={subdirectory} />
    ) : (
      <Button
        aria-label='schedule now'
        className={styles.cardAction}
        color='primary'
        component={NavLink}
        disableElevation
        onClick={selectAppointment}
        to={`${subdirectory}schedule`}
        variant='contained'
      >
        schedule now
      </Button>
    )

  return (
    <div>
      <RescheduleGuardDialog
        to={`${subdirectory}schedule`}
        handleConfirm={reschedAppt}
        handleClose={handleClose}
        showConfirm={showConfirm}
        message='Are you sure you would like to reschedule this appointment?'
      />
      <h5 className={styles.apptContSubHead}>{apptTime.date}</h5>
      <Card className={styles.appointmentInfoCard} variant='outlined'>
        <CardHeader
          title={
            <div className={styles.cardHeader}>
              {`${
                !isNextAppts ? apptTime.hours + ' | ' : ''
              } ${patientName} ${apptType}`}
            </div>
          }
          action={
            <div>
              {isNextAppts ? (
                scheduleNowButton()
              ) : (
                <RescheduleMoreInfoButton
                  appointment={appointment}
                  expanded={expanded}
                  handleExpandClick={handleExpandClick}
                  nextVisits={nextVisits}
                  practice={practice}
                  reschedAppt={promptShouldReschedAppt}
                  subdirectory={subdirectory}
                />
              )}
            </div>
          }
        />
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <AppointmentCardContent
            appointment={appointment}
            practice={practice}
          />
        </Collapse>
      </Card>
    </div>
  )
}
