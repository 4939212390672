import React from 'react'
import { Button } from '@material-ui/core'
import styles from '../schedule.module.css'

export default function DialogSuccess(props) {
  const { handleDialogClose } = props

  return (
    <div className={styles.dialogSuccess}>
      <div className={styles.dialogSuccessItem}>
        <h5 className={styles.dialogHeaderText}>Congratulations!</h5>
      </div>
      <div className={styles.dialogSuccessItem}>
        <p className={styles.dialogSuccessText}>
          The details of your appointment have been emailed to you.
        </p>
      </div>
      <div className={styles.dialogSuccessItem}>
        <Button
          className={styles.button}
          color="primary"
          key="done"
          onClick={handleDialogClose}
          variant="contained"
        >
          Done
        </Button>
      </div>
    </div>
  )
}
