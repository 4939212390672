import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { findPatientLocation, isUpcoming } from '../../services/utils'
import { useGlobalState } from '../../state'
import ScheduleSelector from './ScheduleSelector'
import { selectorList, findSchedItem } from './utils'
import ScheduleContainer from './ScheduleContainer'

export default function ScheduleSetPage() {
  const [
    {
      apptToReschedule,
      nextVisitToSchedule: { patient, nextVisit, location },
      patients,
      practice,
    },
    dispatch,
  ] = useGlobalState()

  const [recommendedDate, setRecommendedDate] = useState()

  const shapeSelectors = useCallback(() => {
    const list = selectorList(
      patients,
      practice,
      patient,
      nextVisit,
      location,
      apptToReschedule
    )
    if (!!nextVisit) {
      setRecommendedDate(moment(nextVisit.date_specified).format('MMM Do'))
    }
    return list
  }, [patients, practice, patient, nextVisit, location, apptToReschedule])

  const [selectors, setSelectors] = useState(() => shapeSelectors())

  useEffect(() => {
    setSelectors(shapeSelectors())
  }, [setSelectors, shapeSelectors])

  const updateNextVisitState = (
    selectedPatient,
    selectedAppt,
    selectedLocation
  ) => {
    dispatch({
      type: 'setNextVisitToSchedule',
      value: {
        patient: selectedPatient,
        nextVisit: selectedAppt,
        location: selectedLocation,
      },
    })
  }

  const setSelectedPatient = (eventVal, list) => {
    const { locations } = practice
    if (patient.id !== eventVal) {
      const currPatient = findSchedItem(eventVal, list, 'id')
      const futureAppts = currPatient.next_visits.filter((visit) =>
        isUpcoming(visit.date_specified, visit.window)
      )
      let currAppt = futureAppts.find((visit) => !visit.booked)
      if (!!currAppt) {
        setRecommendedDate(moment(currAppt.date_specified).format('MMM Do'))
      }
      if (!currAppt) currAppt = { id: '', label: '' }
      const currLocation = findPatientLocation(currPatient, locations)
      updateNextVisitState(currPatient, currAppt, currLocation)
    }
  }

  const setVisitToSchedule = (event, type, list) => {
    const eventVal = event.target.value

    switch (type) {
      case 'patientName':
        setSelectedPatient(eventVal, list)
        break
      case 'apptTypeDate':
        const schedItem = findSchedItem(eventVal, list, 'id')
        setRecommendedDate(moment(schedItem.date_specified).format('MMM Do'))
        updateNextVisitState(patient, schedItem, location)
        break
      default:
        updateNextVisitState(
          patient,
          nextVisit,
          findSchedItem(eventVal, list, 'id')
        )
        break
    }
  }

  const isDisabled =
    patient.label === '' || nextVisit.label === '' || location.label === ''

  const selectComps = selectors.map((selector) => (
    <ScheduleSelector
      currentSelected={selector.currentSelected}
      handleChange={setVisitToSchedule}
      key={selector.id}
      label={selector.label}
      list={selector.list}
      recommendedDate={recommendedDate}
      type={selector.type}
    />
  ))

  return (
    <div>
      <ScheduleContainer selectComps={selectComps} isDisabled={isDisabled} />
    </div>
  )
}
