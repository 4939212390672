import fetchApi from './fetchApi'
import { subdirectoryStorage, tokenStorage } from './localStorage'
import { findSubdirectory } from './utils.js'

const fetchToken = async () => {
  const token = tokenStorage.get()
  const subdirectory = subdirectoryStorage.get()
  const currSubdirectory = findSubdirectory()
  const cleanedSubdirectory = currSubdirectory
    .split('/')
    .filter(str => str !== '')[0]
  if (!token || token === 'undefined' || cleanedSubdirectory !== subdirectory) {
    if (cleanedSubdirectory === undefined) {
      alert('This URL does not exist.')
    }
    const route = `token?subdirectory=${cleanedSubdirectory}`
    const header = {
      headers: {
        Authorization: process.env.REACT_APP_TOKEN,
        origin: window.location.href,
      },
      method: 'GET',
    }
    const practiceSpecificToken = await fetchApi(route, header)
    if (practiceSpecificToken === null) {
      alert('This URL does not exist.')
    }
    tokenStorage.set(practiceSpecificToken.token)
    subdirectoryStorage.set(practiceSpecificToken.subdirectory)
    return practiceSpecificToken.token
  }
  return token
}

const fetchHelper = async (route, body, verb) => {
  const token = await fetchToken()
  const updatedHeader = {
    headers: {
      Authorization: token,
      origin: window.location.href,
    },
    method: verb,
    body,
  }
  return fetchApi(route, updatedHeader)
}

const patchBooking = (bookingId, status) => {
  const body = JSON.stringify({
    booking_id: bookingId,
    status: status,
  })
  const route = `bookings`
  return fetchHelper(route, body, 'PATCH')
}

export const bookAppointment = (bookingId, patientId, appointmentTypeId) => {
  const body = JSON.stringify({
    booking_id: bookingId,
    patient_id: patientId,
    appointment_type_id: appointmentTypeId,
  })
  const route = `bookings`
  return fetchHelper(route, body, 'POST')
}
export const codeCheck = (email, code) => {
  const route = `code?&email=${email}&code=${code}`
  return fetchHelper(route, null, 'GET')
}
export const createUser = email => {
  const body = JSON.stringify({
    email: email,
    version: '01.03',
  })
  const route = `signup`
  return fetchHelper(route, body, 'POST')
}

export const deleteScheduledAppointment = appointmentId => {
  const route = `appointments?&id=${appointmentId}`
  return fetchHelper(route, null, 'DELETE')
}

export const deleteUser = email => {
  const body = JSON.stringify({ email: email })
  const route = `signup`
  return fetchHelper(route, body, 'DELETE')
}

export const emailCheck = email => {
  const route = `email?&email=${email}`
  return fetchHelper(route, null, 'GET')
}

export const getBookings = (nextVisitId, locationId) => {
  const route = `bookings?&next_visit_id=${nextVisitId}&location_id=${locationId}`
  return fetchHelper(route, null, 'GET')
}

export const getPatients = email => {
  const route = `patients?email=${email}`
  return fetchHelper(route, null, 'GET')
}

export const getPractice = () => {
  const route = `practice`
  return fetchHelper(route, null, 'GET')
}

export const passwordCheck = password => {
  const route = `password?password=${password}`
  return fetchHelper(route, null, 'GET')
}

export const setBookingPending = bookingId => {
  return patchBooking(bookingId, 'pending')
}

export const setBookingOpen = bookingId => {
  return patchBooking(bookingId, 'open')
}

export const updatePatientInformation = (id, updates) => {
  const route = `patients/${id}`
  return fetchHelper(route, JSON.stringify(updates), 'POST')
}
