import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'

export const formatPhoneNumberDotSpacing = phoneNumber => {
  if (phoneNumber) {
    const phoneNumberSplit = phoneNumber.slice(2)
    const numberFirst = phoneNumberSplit.slice(0, 3)
    const numberSecond = phoneNumberSplit.slice(3, 6)
    const numberThird = phoneNumberSplit.slice(6, 10)
    return `${numberFirst}.${numberSecond}.${numberThird}`
  }
  return null
}

const validatePhoneNumber = (phoneNumber, country) => {
  if (phoneNumber && phoneNumber.length > 5) {
    const submittedNumber = new AsYouType(country)
    submittedNumber.input(phoneNumber)
    if (submittedNumber.getNumber().isValid()) {
      return submittedNumber.getNumber().number
    }
  }
  return false
}

export const formatPhoneNumberInput = (newValue, oldLength, country) => {
  const asYouType = new AsYouType(country)
  const formattedNumber = asYouType.input(newValue)
  const phoneNumber =
    newValue.length < 9 && newValue.length < oldLength
      ? newValue
      : formattedNumber
  const isValid = !!validatePhoneNumber(newValue, country)
  return [phoneNumber, isValid]
}

export const formatPhoneNumberDomestic = num =>
  !!num ? parsePhoneNumber(num).formatNational() : ''
