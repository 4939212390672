import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import WeekSelectorBanner from './WeekSelectorBanner'
import BookingsContainer from './BookingsContainer'
import BookingsFooter from './BookingsFooter'
import { useGlobalState } from '../../../state'
import {
  getBookings,
  setBookingPending,
  setBookingOpen,
  bookAppointment,
  getPatients,
  deleteScheduledAppointment,
} from '../../../services/fetchHelpers'
import styles from '../schedule.module.css'
import Socket from '../../socket/Socket'
import { filterPatientNextVisits } from '../../../services/utils'
import Spinner from '../../../services/Spinner'
import NoBookingsPopOver from './NoBookingsPopOver'
import { createDateArr, sortBookings } from './utils'

export default function BookingsCalendar(props) {
  const { history } = props
  const [
    {
      apptToReschedule,
      date,
      bookings,
      selectedBooking,
      nextVisitToSchedule: { patient, nextVisit, location },
      user,
      subdirectory,
    },
    dispatch,
  ] = useGlobalState()
  const [initializing, setInitializing] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (nextVisit.id) {
        setInitializing(true)
        const b = await getBookings(nextVisit.id, location.id)
        setInitializing(false)
        dispatch({
          type: 'initializeBookings',
          value: {
            bookings: sortBookings(b.bookings),
            date: moment(new Date(nextVisit.date_specified)),
          },
        })
      }
    })()
  }, [nextVisit, nextVisit.id, location.id, dispatch])

  const setGlobalState = (type, value) => {
    dispatch({
      type: type,
      value: value,
    })
  }

  const handleNext = () => {
    if (isDisabled('next')) return
    setGlobalState('setDate', date.clone().add(7, 'days'))
  }

  const handlePrev = () => {
    if (isDisabled('prev')) return
    setGlobalState('setDate', date.clone().subtract(7, 'days'))
  }

  const isDisabled = direction => {
    const offset = direction === 'next' ? 7 : -14

    return !createDateArr(date.clone().add(offset, 'days'), 14).some(
      day => bookings[day] !== undefined
    )
  }

  const handleDatepicker = newDate => {
    setGlobalState('setDate', moment(newDate))
  }

  const handleSelectBooking = async booking => {
    if (fetching) return
    if (booking.status !== 'open') {
      alert('This booking is no longer available! Please select another.')
      return
    }
    setFetching(true)
    if (!!selectedBooking) {
      await setBookingOpen(selectedBooking.id)
    }
    await setBookingPending(booking.id)
    setGlobalState('setSelectedBooking', {
      ...booking,
      status: 'pending',
    })
    setFetching(false)
  }

  const handleRemoveBooking = async () => {
    if (fetching) return
    setFetching(true)
    await setBookingOpen(selectedBooking.id)
    setGlobalState('clearSelectedBooking')
    setFetching(false)
  }

  const handleReviewAndConfirm = () => {
    if (correspondingBooking(selectedBooking).status === 'open') {
      alert('Your booking selection has timed out! Please try again.')
      dispatch({ type: 'clearSelectedBooking' })
      return
    }
    setDialogOpen(true)
  }

  const correspondingBooking = selected => {
    const bookingsArr =
      bookings[moment(selected.start_datetime).format('ddd D MMM YYYY')]
    return bookingsArr.find(el => el.id === selected.id)
  }

  const handleDialogClose = async () => {
    if (success) {
      const patientData = await getPatients(user.attributes.email)
      const cleanedPatientData = await filterPatientNextVisits(
        patientData.patients
      )
      await setGlobalState('successRefetch', cleanedPatientData)
      history.push(`${subdirectory}`)
    }
    setDialogOpen(false)
  }

  const handleConfirm = async () => {
    setConfirming(true)
    if (apptToReschedule) await deleteScheduledAppointment(apptToReschedule.id)
    await bookAppointment(
      selectedBooking.id,
      patient.id,
      nextVisit.appointment_type_id
    )
    setConfirming(false)
    setSuccess(true)
  }

  const handleBack = () => {
    if (selectedBooking) handleRemoveBooking()
    setGlobalState('setCalendarView', false)
  }

  return (
    <div className={styles.bookingsCalendar}>
      {initializing && <Spinner absolute />}
      <span
        className={styles.calendarBackButton}
        variant="contained"
        onClick={handleBack}
      >
        {`< Back`}
      </span>
      <NoBookingsPopOver initializing={initializing} />
      <WeekSelectorBanner
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleDatepicker={handleDatepicker}
        date={date}
        isDisabled={isDisabled}
        bookings={bookings}
      />
      <BookingsContainer
        dateArr={createDateArr(date, 7)}
        bookings={bookings}
        handleSelectBooking={handleSelectBooking}
        selectedBooking={selectedBooking}
      />
      <BookingsFooter
        apptToReschedule={apptToReschedule}
        confirming={confirming}
        dialogOpen={dialogOpen}
        handleConfirm={handleConfirm}
        handleDialogClose={handleDialogClose}
        handleRemoveBooking={handleRemoveBooking}
        handleReviewAndConfirm={handleReviewAndConfirm}
        location={location}
        nextVisit={nextVisit}
        patient={patient}
        selectedBooking={selectedBooking}
        success={success}
      />
      <Socket channelName="BookingsChannel" />
    </div>
  )
}
