import React from 'react'
import { IconButton, Button } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import moment from 'moment-timezone'
import ToolTipButton from './ToolTipButton'
import styles from './portal.module.css'

export default function RescheduleMoreInfoButton(props) {
  const {
    appointment,
    expanded,
    handleExpandClick,
    nextVisits,
    practice,
    reschedAppt,
    subdirectory,
  } = props

  const isInBookingWindow =
    moment().add(2, 'days').isBefore(moment(appointment.appointment_datetime)) &&
    moment().add(28, 'weeks').isAfter(moment(appointment.appointment_datetime))

  const hasNextVisit = () => {
    const nextVisitsByApptType = nextVisits.filter(
      (nv) => nv.appointment_type_id === appointment.appointment_type_id
    )
    const foundAppt = nextVisitsByApptType.find(
      ({ date_specified: dateSpecified, window}) =>
        moment(appointment.appointment_datetime).subtract(window, 'weeks').isBefore(
          moment(dateSpecified)
        ) &&
        moment(appointment.appointment_datetime).add(1, 'weeks').isAfter(
          moment(dateSpecified)
        )
    )
    return !!foundAppt
  }

  const buttonDisabled = !(
    isInBookingWindow && 
    hasNextVisit() &&
    practice.allow_rescheduling
  )

  return (
    <div className={styles.reschedContainer} >
      {!appointment.hasOwnProperty('booking_id') && (
        buttonDisabled ? (
          <ToolTipButton subdirectory={subdirectory} reschedule/>
        ) : (
          <Button
            aria-label="Reschedule"
            className={styles.reschedButton}
            color="primary"
            disableElevation
            onClick={reschedAppt}
            variant="contained"
          >
            Reschedule
          </Button>
        )
      )}
      <IconButton
        aria-expanded={expanded}
        className={styles.cardAction}
        onClick={handleExpandClick}
      >
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    </div>
  )
}
