import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import moment from 'moment-timezone'
import { useGlobalState } from '../../state'
import SideBarDropDown from './SideBarDropDown'
import styles from './navigation.module.css'
import {
  filterInactiveLocations,
  addressFormatter,
} from '../../services/utils'
import { formatPhoneNumberDotSpacing } from '../../services/phoneNumberFormatters'

export default function SideBar() {
  const [{ activePatient, practice, subdirectory }] = useGlobalState()

  const location = useLocation()

  const isDisabled =
    (activePatient &&
      !activePatient.next_visits.some(
        ({ booked, date_specified: dateSpecified, window }) =>
          !booked &&
          moment().isBefore(moment(dateSpecified).add(window, 'weeks'))
      )) ||
    location.pathname.includes('schedule')

  const locationList = filterInactiveLocations(practice.locations).map(
    (location) => {
      const { id, name, office_phone } = location
      const niceNumber = formatPhoneNumberDotSpacing(office_phone)
      return (
        <div key={id}>
          {name && (
            <div className={`${styles.sideLocation} ${styles.side}`}>
              <h6>{name}</h6>
              <p className='pre-wrap-white-space'>{addressFormatter(location)}</p>
              {office_phone && <a href={`tel:${office_phone}`}>{niceNumber}</a>}
            </div>
          )}
        </div>
      )
    }
  )

  return (
    <div className={styles.sideBar}>
      <SideBarDropDown />
      <div className={styles.sideBody}>
        <h5 className={styles.sideTitle}>{practice.name}</h5>
        <div className={styles.sidePracContainer}>{locationList}</div>
      </div>
      <div className={styles.sideFooter}>
        <Button
          className={styles.appButton}
          component={NavLink}
          color="primary"
          disabled={isDisabled}
          disableElevation
          fullWidth
          to={`${subdirectory}schedule`}
          variant="contained"
        >
          Schedule Now
        </Button>
      </div>
    </div>
  )
}
