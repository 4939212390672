import * as SCHED from '../reducerTypes'

export default {
  [SCHED.ACTIVE_PATIENT]: (state, action) => ({
    ...state,
    activePatient: action.value.patient,
    nextVisitToSchedule: action.value.nextVisit,
    calendarView: false,
    bookings: {},
    selectedBooking: null,
  }),
  [SCHED.NEXT_VISIT_TO_SCHEDULE]: (state, action) => ({
    ...state,
    calendarView: false,
    nextVisitToSchedule: action.value,
    bookings: {},
    selectedBooking: null,
  }),
  [SCHED.APPT_TO_RESCHEDULE]: (state, action) => ({
    ...state,
    calendarView: false,
    nextVisitToSchedule: action.value.nextVisitToSchedule,
    apptToReschedule: action.value.apptToReschedule,
    bookings: {},
    selectedBooking: null,
  }),
  [SCHED.CLEAR_APPT_TO_RESCHED]: (state) => ({
    ...state,
    apptToReschedule: null
  }),
  [SCHED.DATE]: (state, action) => ({
    ...state,
    date: action.value,
  }),
  [SCHED.CALENDAR_VIEW]: (state, action) => ({
    ...state,
    calendarView: action.value,
  }),
  [SCHED.BOOKINGS]: (state, action) => ({
    ...state,
    bookings: { ...action.value },
  }),
  [SCHED.INITIALIZE_BOOKINGS]: (state, action) => ({
    ...state,
    bookings: { ...action.value.bookings },
    date: action.value.date,
  }),
  [SCHED.SELECTED_BOOKING]: (state, action) => ({
    ...state,
    selectedBooking: { ...action.value },
  }),
  [SCHED.CLEAR_SELECTED_BOOKING]: (state) => ({
    ...state,
    selectedBooking: null,
  }),
  [SCHED.CLEAR_BOOKINGS]: (state) => ({
    ...state,
    calendarView: false,
    nextVisitToSchedule: {
      patient: { id: '', label: '' },
      nextVisit: { id: '', label: '' },
      location: { id: '', label: '' },
    },
    apptToReschedule: null,
    bookings: {},
    selectedBooking: null,
  }),
  [SCHED.SUCESS_REFETCH]: (state, action) => ({
    ...state,
    patients: action.value,
    activePatient: null,
    calendarView: false,
    nextVisitToSchedule: {
      patient: { id: '', label: '' },
      nextVisit: { id: '', label: '' },
      location: { id: '', label: '' },
    },
    apptToReschedule: null,
    bookings: {},
    selectedBooking: null,
  }),
}
