import React from 'react'
import Button from '@material-ui/core/Button'
import DotsStepper from './DotsStepper'
import TermsCheckbox from './TermsCheckbox'
import { useGlobalState } from '../../state'
import styles from './auth.module.css'

export default function Footer(props) {
  const [{ passValidity, legalAgreed }] = useGlobalState()
  const {
    type,
    pageNum,
    handleNext,
    handlePrev,
    buttonText,
    textObject,
    numPages,
    isSignUp = false,
  } = props

  const allowRegister = () => {
    if (isSignUp) {
      return Object.values(passValidity).every((test) => test) && legalAgreed
    } else {
      return Object.values(passValidity).every((test) => test)
    }
  }

  const disableNext = () => {
    return type === 'password' && !allowRegister()
  }

  return (
    <div className={styles.footer}>
      {type === 'password' && isSignUp && <TermsCheckbox />}
      {handleNext && (
        <div className={styles.footer}>
          <Button
            className={styles.appButton}
            fullWidth
            disableElevation
            onClick={handleNext}
            variant="contained"
            color="primary"
            disabled={disableNext()}
          >
            {buttonText}
          </Button>
          {handlePrev && (
            <Button
              className={styles.appButtonSpaced}
              color="primary"
              disableElevation
              onClick={() => handlePrev(type)}
              variant="outlined"
            >
              prev
            </Button>
          )}
          <span className={styles.textObject} onClick={textObject.onClick}>
            {textObject.text}
          </span>
          <DotsStepper pageNum={pageNum} numPages={numPages} />
        </div>
      )}
    </div>
  )
}
