import moment from 'moment-timezone'
import { useGlobalState } from '../../state'

export default function BookingsSocket(props) {
  const [{ bookings }, dispatch] = useGlobalState()
  const { booking } = props
  
  if (booking === null ) return null
  const date = moment(booking.start_datetime).format('ddd D MMM YYYY')
  if (bookings[date] === undefined) return null
  const bookingInd = bookings[date].findIndex(b => b.id === booking.id)
  if (bookingInd === -1) return null
  let bookingDateArr = bookings[date].slice()
  if (bookingDateArr[bookingInd].status === booking.status) return null
  bookingDateArr[bookingInd] = booking
  const updatedBookings = {
    ...bookings,
    [date]: bookingDateArr,
  }
  dispatch({
    type: 'setBookings',
    value: updatedBookings,
  })
  return null
}
