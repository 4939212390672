import React from 'react'
import moment from 'moment-timezone'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CloseIcon from '@material-ui/icons/Close'
import styles from '../schedule.module.css'

export default function SelectedBooking(props) {
  const {
    selectedBooking,
    firstName,
    appointmentType,
    handleRemoveBooking,
  } = props

  const formattedDatetime = moment(selectedBooking.start_datetime).format(
    'ddd, MMMM D, YYYY | h:mm a'
  )

  return (
    <Card>
      <CardContent className={styles.selectedBooking}>
        <div className={styles.detailsBox}>
          <p>{formattedDatetime}</p>
          <p>{`${firstName} for ${appointmentType}`}</p>
        </div>
        <div
          onClick={handleRemoveBooking}
          className={styles.removeBox}
          role="button"
          tabIndex="-1"
        >
          <p className={styles.removeText}>Remove</p>
          <CloseIcon />
        </div>
      </CardContent>
    </Card>
  )
}
