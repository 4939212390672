export default function TermsOfUse() {
  return `Terms of Use and Privacy Policy for OpenChair\n\n
  Effective March 10, 2020 (rev01.03)\n\n
  1. Introduction.  OpenChair is a web-based tool to that allows patients to directly book appointments with participating orthodontic and dental practices.  These Terms of Use and Privacy Policy (collectively, “Terms”) govern your use of OpenChair including all use and submissions.  OpenChair is provided by Prominent Edge LLC (“we” or “us”).  In this Agreement, the term “you” means the person who is agreeing to these Terms.  By using, registering, or making appointments for OpenChair, you signify electronically your agreement to be bound by these Terms.  If you do not agree to these Terms, do not sign up or use OpenChair.\n\n
  2. Updates.  These Terms are effective as of the date first written above.  We may update these terms from time to time by posting such updated Terms to www.patientportal.threadcommunication.com or by providing email notice to you.  Terms posted to www.patientportal.threadcommunication.com shall become effective upon posting.  Terms provided via email (or via a link in an email) shall become effective upon sending by us.  In the event of an update, by continuing to use OpenChair you agree to be bound by any such updated Terms.  If you do not agree, you must immediately stop using OpenChair.  We may change or discontinue any feature of OpenChair at any time without notice.\n\n
  3. Registration.  We will open an account for you when you sign up for OpenChair. You must provide complete and accurate registration information. You agree to keep this information up-to-date. You represent and warrant that: (1) you have the full power, authority, and legal capacity to enter into the Agreement and follow its obligations, and (2) all information that you provide is true and current.\n\n
  4. Fees, Billing, and Payment.  OpenChair is free for patients to use!\n\n
  5. Termination.  You may close your account at any time by providing notice to us.  We may cancel or suspend your access to OpenChair at any time, without cause and/or without notice and without further liability or obligation. Your right to use OpenChair will end once account is closed.\n\n
  6. License.  We grant you a personal, non-exclusive, non-transferable, limited and revocable license to use OpenChair for your own use and provided that you comply and remain in compliance with this Agreement. You may not circumvent any mechanisms for preventing the unauthorized access, reproduction, or distribution of OpenChair. Your license terminates immediately upon cancellation or termination of your OpenChair account or if we believe you are in violation of these Terms.  You may not (a) sell or redistribute OpenChair or provide your user credentials to another person or entity, (b) incorporate any aspect of OpenChair into another product, or (c) reverse engineer, decompile or disassemble OpenChair or otherwise attempt to derive the source code from OpenChair (except where expressly permitted by law). You may not modify, adapt or create derivative works from OpenChair in any way or remove proprietary notices posted on OpenChair.  You may access OpenChair only through the software, interfaces, and protocols provided or authorized by us. You may not authorize or assist any third party to do any of the things prohibited in this paragraph. Any rights not specifically licensed to you for any OpenChair software are reserved by us.  Your license will end on the date your OpenChair account is closed or upon notice from us.\n\n
  7. No Warranty.  We provide OpenChair “as is”, “with all faults” and “as available.” YOUR USE OF OPENCHAIR IS AT YOUR OWN RISK. We make no express warranties or guarantees about OpenChair or any of its information. TO THE EXTENT PERMITTED BY LAW, WE DISCLAIM IMPLIED WARRANTIES THAT THE WEB SITES, SERVICES, CONTENT AND MATERIALS AVAILABLE THROUGH OPENCHAIR ARE MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED, TIMELY, RELIABLE, NON-INFRINGING or that the website, data, or the server(s) on which they are hosted are free of viruses, bugs or other harmful components. WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF ANY OF THE FOREGOING, WILL BE EFFECTIVE, RELIABLE, ACCURATE OR MEET YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE OPENCHAIR AT TIMES OR LOCATIONS OF YOUR CHOOSING. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY OUR REPRESENTATIVES SHALL CREATE A WARRANTY.\n\n
  Every effort is made to assure that all information published is correct. However, we disclaim any liability for errors or omissions in the services and you assume the risk of possible errors or omissions. No advice is intended or offered by OpenChair in making any of the information available, and we disclaim any and all liability related to any decision take by a party in reliance upon any information provided.\n\n
  8. Limited Liability. WE SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF OPENCHAIR. THESE EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE, COMPUTER INFECTION, OR MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, EVEN IF WE KNEW OR SHOULD HAVE KNOWN THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, OUR LIABILITY IN SUCH STATE OR JURISDICTION SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. IN NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO THE USE OF OPENCHAIR EXCEED THE TOTAL AMOUNT YOU PAID FOR THE PARTICULAR PURCHASE AT ISSUE.\n\n
  9. Indemnification.  Upon a request by us, you agree to indemnify, defend and hold harmless us and our respective employees, attorneys, assigns, successors-in-interest, contractors, vendors, suppliers, licensors, licensees and sublicensees, from and against any and all claims, damages, causes of action, costs (including reasonable attorneys’ fees) and other expenses that arise directly or indirectly out of or from: (1) your actual or alleged breach of these Terms; (2) any allegation that any information, data, or other material you have submitted or transmitted to us infringes, misappropriates or otherwise violates the privacy, HIPAA, copyright, trademark, trade secret or other intellectual property rights of any party; and/or (3) your activities or omissions in connection with the information provided via OpenChair. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.\n\n
  10. Privacy Policy and Use of Information.\n\n
  \t10.1. Website Usage Information.  We may receive and store certain types of website and email usage information whenever you visit any of our web sites or open one of our OpenChair emails. For example, we may collect the page served, time, source of the request, type of browser making the request, preceding page view, previously stored “cookies”, and other similar information. With regard to email messages, we may store information about whether or not the email was viewed, whether or not images were downloaded from our server, links that may have been accessed, the identity of the subscriber, and whether or not the report has been forwarded to others. When analyzed, website and email usage information helps us determine how subscribers and visitors utilize information, what type of information is most popular, and the manner in which information is being viewed.\n\n
  \t10.2. Cookies.  OpenChair uses cookies to collect information about your access and the services we provide. Cookies are pieces of information which include a unique reference code that a website transfers to your device to store and sometimes track information about you. Some of our cookies last only for the duration of your web session and expire when you close your browser. Other cookies are used to remember you when you return to the website and will last for longer. Cookies are uniquely assigned to you and can only be read by a server at the domain that issued the cookie. We may use cookies to (1) remember that you visited the site before, and to track unique visitors; (2) remember your login information as you navigate through our website; (3) recording how users navigate our website so that we may improve our services; (4) collect statistical information about how users utilize OpenChair; and (5) maintain a record of the pages within our website that are visited, and to monitor website traffic.\n\n
  \t10.3. Your Personal Information. We may receive and store certain types of personal information provided by you or a participating dentist or orthodontist in the course of providing service. For example, we may collect your name, date of birth, email address, and appointment times.  We may collect notes from you regarding the purpose of scheduled appointments. We record changes to your account status. For example, we may record when your account was accessed, when your password was reset, and similar audit information.\n\n
  \t10.4. Our Use of Your Information.  The information you provide to us may be used to (1) send you e-mail communications; (2) contact you about changes to our web sites or our services (e.g., notification of changes to these Terms, or other policies that affect your use of our web sites); (3) confirm or schedule an appointment with your dentist or orthodontist; (4) provide any information you provide to us to your dentist or orthodontist; (5) monitor or improve the site; (6) administer the site’s systems and for other internal business purposes; (7) customize the content you see; (8) comply with and monitor compliance with our Terms and other applicable agreements and polices; and (9) for other purposes disclosed when you provide your information.\n\n
  \t10.5. Information Sharing.  We may employ or contract with third parties to perform functions on our behalf; examples include hosting or operating our site, scheduling appointments with your dentist or orthodontist, sending e-mail, data analysis, marketing assistance, and customer service. These third parties may have access to your personal information for the purpose of performing such functions on the site’s behalf. We do not sell personal information to third parties or provide it to them for any purpose other than supporting the scheduling of appointments with your doctor or orthodontist.\n\n
  11. This is an Electronic Contract.  Your affirmative act of making purchases or registering for OpenChair constitutes your electronic signature to these Terms and your consent to enter into agreements with us electronically. You also agree that we may send to you in electronic form any privacy or other notices, disclosures, reports, documents, communications or other records regarding OpenChair (collectively, “Notices”). We can send you electronic Notices (1) to the e-mail address that you provided to us during registration or (2) by posting the Notice on the applicable web page of OpenChair. The delivery of any Notice from us is effective when sent by us, regardless of whether you read the Notice when you receive it or whether you actually receive the delivery. You can withdraw your consent to receive Notices electronically by closing your account with us.\n\n
  In order to receive Notices electronically, you must constantly maintain the email address or web address which you have provided to us.  All contracts completed electronically will be deemed for all legal purposes to be in writing and legally enforceable as a signed writing.\n\n
  12. Miscellaneous.  These Terms and any supplemental terms, updates, policies, rules and guidelines posted on OpenChair constitute the entire agreement between you and us and supersede all previous written or oral agreements. If any part of these Terms are held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.  You agree that the laws of California govern this contract and any claim or dispute that you may have against us, without regard to conflict of laws rules. You further agree that any disputes or claims that you may have against us will be resolved by a court located in San Francisco, California, and you consent to personal and subject matter jurisdiction in such courts.  We may assign this contract at any time without notice to you. You may not assign this contract to anyone else.\n\n
  12. Contact.  If you have any questions or concerns about these Terms, about any information that we may have collected or stored, or to delete, opt out or modify any of the information contained in our database, please contact us directly at Rooster Grin Media LLC, 575 Sutter St Suite 303, San Francisco, CA 94102, info@roostergrin.com.`
}
