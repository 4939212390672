import React from 'react'
import './App.css'
import AppRouter from './AppRouter.js'
import { StateProvider } from './state'
import { StylesProvider } from '@material-ui/core/styles'
import AppGlobalStyles from './AppGlobalStyles'
import { appReducer } from './state/reducers'
import { initialState } from './state/initialState'

export default function App() {
  return (
    <StateProvider initialState={initialState} reducer={appReducer}>
      <StylesProvider injectFirst>
        <AppGlobalStyles>
          <div className="App">
            <AppRouter />
          </div>
        </AppGlobalStyles>
      </StylesProvider>
    </StateProvider>
  )
}
