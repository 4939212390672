import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Tooltip } from '@material-ui/core'
import styles from './portal.module.css'

export default function ToolTipButton(props) {
  const { subdirectory, reschedule } = props
  const re = reschedule ? 're' : ''
  return (
    <Tooltip
      arrow
      placement="left"
      title={
        <div className={styles.cardTooltip}>
          {`Please call the office to ${re}schedule this appointment`}
        </div>
      }
    >
      <div>
        <Button
          aria-label="schedule now"
          className={styles.cardAction}
          component={NavLink}
          disabled
          to={`${subdirectory}schedule`}
          variant="contained"
        >
          schedule now
        </Button>
      </div>
    </Tooltip>
  )
}
