import React, { useState, useRef, useEffect } from 'react'
import { TextField } from '@material-ui/core/'
import { useAuthHandleChange } from '../../services/authHelpers'
import PasswordValidations from '../../services/PasswordValidations'
import PasswordVisibility from '../../services/PasswordVisibility'
import styles from './auth.module.css'

export default function AuthInputField(props) {
  const [showPassword, setShowPassword] = useState(false)
  const {
    type,
    value,
    validate,
    authErrors,
    handleResendEmail,
    handleSubmit,
  } = props
  const inputEl = useRef(null)

  useEffect(() => {
    const inputContentLen = inputEl.current.value.length
    inputEl.current.selectionStart = inputContentLen
  }, [showPassword])

  const handleChange = useAuthHandleChange()

  const handleEnterKey = (event) => {
    if (event.keyCode === 13) handleSubmit()
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownShowPassword = (event) => {
    event.preventDefault()
  }

  const capitalize = (str) => {
    if (typeof str !== 'string') return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const isPassword = () => {
    return type === 'password'
  }

  return (
    <div className={styles.bodyContainer}>
      <TextField
        id={type}
        inputRef={inputEl}
        type={isPassword() && !showPassword ? 'password' : 'text'}
        label={capitalize(type)}
        error={!!authErrors}
        helperText={authErrors}
        name={type}
        value={value}
        onKeyUp={(event) => handleEnterKey(event)}
        onChange={(event) => handleChange(event, type, validate)}
        className={styles.bodyInput}
        InputProps={
          isPassword()
            ? {
                endAdornment: (
                  <PasswordVisibility
                    showPassword={showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                    handleMouseDownShowPassword={handleMouseDownShowPassword}
                  />
                ),
              }
            : null
        }
      />
      {props.hasOwnProperty('handleResendEmail') && (
        <span className={styles.textObject} onClick={() => handleResendEmail()}>
          Resend Email
        </span>
      )}
      {props.hasOwnProperty('textObject') && (
        <span className={styles.textObject} onClick={props.textObject.onClick}>
          {props.textObject.text}
        </span>
      )}
      {validate && <PasswordValidations />}
    </div>
  )
}
