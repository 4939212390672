import React from 'react'
import styles from './portal.module.css'
import AppointmentContainer from './ApptContainer'

export default function Portal() {
  return (
    <div className={styles.contentContainer}>
      <div className={styles.portalContainer}>
        <AppointmentContainer
          title={`Next Recommended Appointments`}
          subTitle={`The best appointment date will appear here. Click “schedule now” and choose the time to confirm.`}
          isNextAppts={true}
        />
        <AppointmentContainer
          title={`Scheduled Appointments`}
          isNextAppts={false}
        />
      </div>
    </div>
  )
}
