import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

export default function AppGlobalStyles(props) {
  const primaryColor = '#3BB1DB'
  const primaryTextColor = '#141414'
  const secondaryColor = '#FF0022'
  // const secondaryHoverColor = '#F54E44'
  // const secondaryDisabledColor = '#981008'

  const button = {
    borderRadius: '4px',
    border: 0,
    color: 'white',
    height: 'auto',
    padding: '10px 12px',
    '&$disabled': {
      color: 'rgba(255, 255, 255, .5)',
    },
  }

  const mobileStepper = {
    dot: {
      width: '12px',
      height: '12px',
    },
    dotActive: {
      backgroundColor: primaryColor,
    },
  }

  const dialog = {
    root: {
      zIndex: '999999',
    },
  }

  const dialogActions = {
    root: {
      padding: '0 24px 24px',
    },
  }

  const dialogContentText = {
    root: {
      whiteSpace: 'pre-wrap',
    },
  }

  const cardHeader = {
    action: {
      marginTop: 0,
    },
  }

  const cardContent = {
    root: {
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  }

  const iconButton = {
    root: {
      color: 'rgba(20, 20, 20, .75)',
      fontSize: '14px',
      fontFamily: ['"Raleway"', 'Arial', 'Sans-Serif'].join(','),
    },
  }

  const input = {
    underline: {
      '&:after': {
        borderBottom: `2px solid ${primaryColor}`,
      },
    },
  }

  const formLabel = {
    root: {
      '&$focused': {
        color: primaryColor,
      },
    },
  }

  const circularProgress = {
    root: {
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
    },
    colorPrimary: {
      color: primaryColor,
    },
  }

  const tooltip = {
    tooltip: {
      fontSize: '12px',
      backgroundColor: 'white',
      color: primaryTextColor,
      boxShadow: '0 1px 4px .5px rgba(0,0,0,.05)',
    },
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    typography: {
      fontFamily: ['"Raleway"', 'Arial', 'Sans-Serif'].join(','),
    },
    overrides: {
      MuiButton: {
        root: {
          ...button,
        },
        text: {
          ...button,
        },
        textPrimary: {
          ...button,
        },
        textSecondary: {
          ...button,
        },
        contained: {
          ...button,
        },
        containedPrimary: {
          ...button,
        },
        containedSecondary: {
          ...button,
        },
        label: {
          letterSpacing: '.5px',
        },
        outlinedPrimary: {
          ...button,
          border: `1px solid ${primaryColor}`,
          backgroundColor: 'rgba(59, 177, 219, .05)',
          color: primaryColor,
          '&:hover': {
            color: primaryColor,
            backgroundColor: 'rgba(59, 177, 219, .25)',
          },
        },
      },
      MuiCircularProgress: circularProgress,
      MuiDialog: dialog,
      MuiDialogActions: dialogActions,
      MuiDialogContentText: dialogContentText,
      MuiCardHeader: cardHeader,
      MuiCardContent: cardContent,
      MuiFormLabel: formLabel,
      MuiIconButton: iconButton,
      MuiInput: input,
      MuiMobileStepper: mobileStepper,
      MuiTooltip: tooltip,
    },
  })

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
}
