import React from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import styles from './auth.module.css';

export default function DotsStepper(props) {

  const { pageNum, numPages } = props

  return (
    <MobileStepper
      variant="dots"
      steps={numPages}
      position="static"
      activeStep={pageNum}
      className={styles.dotStepper}
    />
  );
}