import React from 'react'
import styles from '../schedule.module.css'
import { useGlobalState } from '../../../state'

export default function DialogHeader(props) {
  const { handleDialogClose } = props
  const [{ apptToReschedule }] = useGlobalState()

  return (
    <div className={styles.dialogHeader}>
      <div
        key="back"
        onClick={handleDialogClose}
        className={styles.dialogHeaderBack}
        role="button"
        tabIndex="-1"
      >
        <span>{'< Back'}</span>
      </div>
      <h5 className={styles.dialogHeaderText}>{!!apptToReschedule ? `Review and Reschedule` : `Review and Confirm`}</h5>
    </div>
  )
}
